import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Parser } from 'json2csv';
import { findIndex } from 'lodash';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import {
  DeepReadonly,
  ILocation,
  INode,
  ISecondaryNetworks,
  OptimizationTrigger
} from 'src/app/lib/interfaces/interface';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { OptimizationService } from 'src/app/lib/services/optimization.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { QoeService } from 'src/app/lib/services/qoe.service';
import { SecondaryNetworksService } from 'src/app/lib/services/secondary-networks.service';
import { ThemeService } from 'src/app/lib/services/theme.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import {
  selectCustomer,
  selectLocationList,
  selectPipeLocationOnChange,
  selectPipeLocationOnChangeOptimistic,
  selectWPA3Recommendation
} from 'src/app/store/customer/customer.selectors';
import { optimizationChanged, pollingPull } from 'src/app/store/polling/polling.actions';
import {
  selectLocationInternet,
  selectLocationOptimization,
  selectLocationQoE,
  selectLocationTopology,
  selectNodes
} from 'src/app/store/polling/polling.selector';
import { customerSetTechnician, locationChanged } from 'src/app/store/technician/technician.actions';

@UntilDestroy()
@Component({
  templateUrl: './technician.component.html',
  styleUrls: ['./technician.component.scss']
})
export class TechnicianComponent implements OnInit, OnDestroy {
  nodesPool: any = [];
  nodes: any = [];
  subscriptions: any[] = [];
  debouncerTimeout: any;
  nodeResponse: INode[] = null;
  qoeResponse: any = null;
  topologyResponse: any = null;
  user: any;
  customer$ = this.store.select(selectCustomer);
  location$ = this.store.pipe(selectPipeLocationOnChangeOptimistic);
  permissions: any;
  optimizeState: 'optimized' | 'loading' | 'None' | 'fail' | 'running' = 'None';
  optimized: string = 'None';
  optimizationChange: number = 0;
  loadingNodes: boolean = true;
  isUprise: boolean = false;
  partnerId: string;
  locationId: string;

  timeoutInterval: any;
  timeout: number = 300000;
  emailFormControl: UntypedFormControl = new UntypedFormControl();
  nameFormControl: UntypedFormControl = new UntypedFormControl();
  accountIdFormControl: UntypedFormControl = new UntypedFormControl();

  setEmail: boolean = false;
  setName: boolean = false;
  setAccountId: boolean = false;
  onboardingStatus: string = '';
  onboarded: boolean = false;
  showNetworkSettings: boolean = false;
  helper: GeneralHelper = new GeneralHelper();

  locationList$ = this.store.select(selectLocationList);
  WPA3Recommendation$ = this.store.select(selectWPA3Recommendation);

  enableOptimizationButton$ = this.optimizationService.enableOptimization$();

  firmware: any = {
    updating: null,
    upgrade: false,
    state: 'updated',
    modal: false,
    nodes: []
  };

  network: {
    ssid: string | null;
    wpa2ssid: string | null;
    wpa2enabled: boolean;
    wpa3ssid: string | null;
    wpa3enabled: boolean;
  } = {
    ssid: null,
    wpa2ssid: null,
    wpa2enabled: false,
    wpa3ssid: null,
    wpa3enabled: false
  };

  secondaryNetworksData: ISecondaryNetworks;

  networkSettingsMode = '';

  constructor(
    public plume: PlumeService,
    private secondaryNetworks: SecondaryNetworksService,
    private troubleShoot: TroubleshootingService,
    private logging: LoggingService,
    private modelRef: ModelRefService,
    private toast: ToastService,
    private qoe: QoeService,
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private store: Store,
    private theme: ThemeService,
    private optimizationService: OptimizationService,
    private customerService: CustomerService
  ) {}

  ngOnInit(): void {
    this.mixpanel.storeEvent('TECH_DASHBOARD_SCREEN');

    this.subscriptions.push(
      this.plume.permissions.subscribe((data: any) => {
        this.permissions = data;
      })
    );

    this.subscriptions.push(
      this.theme.listener.subscribe(() => {
        this.populate();
      })
    );

    this.subscriptions.push(
      this.store.select(selectLocationQoE).subscribe((response: any) => {
        if (response) {
          this.qoeResponse = JSON.parse(JSON.stringify(response));
          this.debounce(() => this.populate(), 500);
        } else {
          this.qoeResponse = { nodes: [] };
        }
      })
    );

    this.subscriptions.push(
      this.store.select(selectNodes).subscribe((nodes) => {
        if (nodes) {
          this.nodeResponse = JSON.parse(JSON.stringify(nodes));
          this.debounce(() => this.populate(), 500);
        }
      })
    );

    this.subscriptions.push(
      this.store.select(selectLocationTopology).subscribe((response: any) => {
        if (response) {
          this.topologyResponse = JSON.parse(JSON.stringify(response));
          this.debounce(() => this.populate(), 500);
        }
      })
    );

    this.subscriptions.push(
      this.store.select(selectLocationInternet).subscribe((response: any) => {
        if (
          response &&
          response.summary &&
          response.summary.onboardingStatus &&
          ['OnboardingComplete', 'PodsAdded'].includes(response.summary.onboardingStatus)
        ) {
          if (response.summary.onboardingStatus === 'PodsAdded') {
            this.onboarded = false;
            this.onboardingStatus = 'onboardingStatus.onboardingPodsAdded';
          } else {
            this.onboarded = true;
            this.onboardingStatus = 'onboardingStatus.onboardingComplete';
          }
        } else {
          this.onboarded = false;
          this.onboardingStatus = 'onboardingStatus.onboardingNotComplete';
        }
      })
    );

    combineLatest([this.store.pipe(selectPipeLocationOnChange), this.store.select(selectCustomer)])
      .pipe(
        filter(([location, customer]) => !!location && !!customer),
        take(1),
        untilDestroyed(this)
      )
      .subscribe(([location]) => {
        this.init(location);
      });

    this.customerService
      .getSSIDs$()
      .pipe(untilDestroyed(this))
      .subscribe((network) => {
        this.network = network;
      });

    this.secondaryNetworks
      .getSecondaryNetworks$()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.secondaryNetworksData = response;
      });
  }

  isSupportRole(): boolean {
    return this.plume.isStrictSupportRole();
  }

  addSubscriptionNotification(): void {
    this.subscriptions.push(
      this.store.select(selectLocationOptimization).subscribe((response: any) => {
        if (response) {
          this.optimizationState(response.state, response.stateChangedAt);
        }
      })
    );
  }

  init(location: DeepReadonly<ILocation>): void {
    this.checkFirmware();

    this.user = this.plume.getUser();
    this.optimizationChange = 0;
    this.isUprise = location.uprise;
    this.partnerId = location.partnerId;
    this.locationId = location.id;

    if (!location.ssid) {
      this.toast.warning('toast.technician.warningMessage', 'toast.technician.warningTitle');
    }

    if (!this.nodesPool || this.nodesPool.length === 0) {
      this.logging.warn('Nodes pool is empty, can not optimize');
    }

    this.optimizeState = 'loading';

    this.troubleShoot.getOptimized().subscribe((response) => {
      this.store.dispatch(
        optimizationChanged({
          optimization: {
            locationId: this.plume.locationid,
            triggers: response.optimization.triggers as OptimizationTrigger[],
            state: response.optimization.state as 'initiated' | 'inProgress' | 'failed' | 'fail' | 'optimized',
            stateChangedAt: response.optimization.stateChangedAt,
            id: response.optimization.id,
            uuid: response.optimization.uuid
          }
        })
      );
      this.optimizationState(
        response.optimization.state || 'techdash.infoArea.ready',
        response.optimization.stateChangedAt || Date.now(),
        true
      );
      this.addSubscriptionNotification();
    });
  }

  debounce(fn: () => void, delay: number): void {
    clearTimeout(this.debouncerTimeout);
    this.debouncerTimeout = setTimeout(fn, delay);
  }

  nodeAdded(added: boolean): void {
    if (added) {
      this.store.dispatch(pollingPull({ debugSource: 'technician node added' }));
    }
  }

  changeLocation(location: any): void {
    this.logging.debug('<changeLocation>');
    this.store.dispatch(locationChanged({ newLocation: location.id }));
    this.mixpanel.storeEvent('CHANGE_LOCATION');
  }

  populate(): void {
    const index = findIndex(this.nodes, { isRename: true });

    if (index < 0) {
      const nodesPool = [];

      if (this.nodeResponse && this.qoeResponse && this.qoeResponse.nodes && this.topologyResponse) {
        this.nodeResponse.forEach((node: any) => {
          const nModel = this.modelRef.get(node.model);

          node.kind = {
            category: node.model || '',
            icon: nModel.icon || '',
            name: node.nickname || node.name || ''
          };

          const edge = this.topologyResponse.edges.find((edge: any) => edge.target === node.id) || null;

          if (edge && edge.metadata) {
            node.channel = {
              mode: edge.metadata.freqBand,
              value: edge.metadata.channel
            };
          } else {
            node.channel = null;
          }

          node.isGateway = this.helper.isGateway(node.id, this.nodeResponse);

          if (node.model === 'VFSC_VOX30_IT') {
            node.disableSpeedTest = true;
          }

          const nodeQoe = this.qoeResponse.nodes.find((nodeQoe: any) => nodeQoe.id === node.id) || null;

          if (nodeQoe) {
            node = this.qoe.getNodeQoeData(nodeQoe, node);
          }

          nodesPool.push(node);
        });

        this.nodesPool = nodesPool;

        this.sort(this.nodesPool);
      } else {
        this.nodesPool = [];
        this.nodes = [];
        this.loadingNodes = false;
      }
    }
  }

  deleteNode(id: string): void {
    this.nodeResponse = this.nodeResponse.filter((node: any) => node.id !== id);
    this.nodesPool = this.nodesPool.filter((node: any) => node.id !== id);
    this.nodes = this.nodes.filter((node: any) => node.id !== id);
  }

  track(index: number, node: any): string {
    return node.id;
  }

  sort(nodes: any[]): void {
    const online = [];
    const offline = [];

    if (this.nodesPool.length) {
      nodes.forEach((node: any) => {
        if (node.connectionState === 'connected') {
          online.push(node);
        } else {
          offline.push(node);
        }
      });

      online.sort((node: any) => (node.isGateway ? -1 : 1));
      offline.sort((node: any) => (node.isGateway ? -1 : 1));

      this.nodes = [...online, ...offline];
    } else {
      this.nodes = [];
    }

    this.loadingNodes = false;
  }

  checkFirmware(repeat: boolean = false): void {
    this.troubleShoot.getFirmware$().subscribe((response) => {
      this.firmware.state = response.summaryState;
      this.firmware.upgrade = response.upgradeRecommended || false;
      this.firmware.upgradePostOnboarding = response.upgradeRecommendedPostOnboarding || false;
      this.firmware.upgradeText = 'techdash.infoArea.upgradeNotRequired';

      if (this.firmware.upgrade) {
        this.firmware.upgradeText = 'techdash.infoArea.upgrade';
      }

      if (this.firmware.upgradePostOnboarding) {
        this.firmware.upgradeText = 'techdash.infoArea.upgradePostOnboarding';
      }

      if (response.nodes && response.nodes.length) {
        response.nodes.forEach((firmwareNode: { nodeId: string; model: INode }) => {
          firmwareNode.model = this.nodes.find((node: INode) => node.id === firmwareNode.nodeId) || null;
        });

        this.firmware.nodes = response.nodes;
      }

      if (repeat && this.firmware.state !== 'updated') {
        this.firmware.updating = setTimeout(() => this.checkFirmware(true), 15000);
      }
    });
  }

  upgradeFirmware(): void {
    this.troubleShoot.upgradeFirmware$().subscribe(() => {
      this.mixpanel.storeEvent('TD_FIRMWARE_UPGRADE');
      this.firmware.modal = true;
      this.checkFirmware(true);
    });
  }

  closeModal(): void {
    this.firmware.modal = false;
  }

  closeAndRefreshNetworkSettings(): void {
    this.showNetworkSettings = false;
    this.secondaryNetworks.getSecondaryNetworks$().subscribe((response: ISecondaryNetworks) => {
      this.secondaryNetworksData = response;
    });
  }

  triggerOnboarding(location: DeepReadonly<ILocation>): void {
    if (!this.onboarded) {
      const onboard = () => {
        this.mixpanel.storeEvent('TD_ONBOARDING_COMPLETE');
        this.onboardingStatus = 'onboardingStatus.onboardingPending';
        this.troubleShoot.setOnboarding('OnboardingComplete').subscribe((response: any) => {
          this.store.dispatch(pollingPull({ debugSource: 'technician trigger onboarding' }));
          this.logging.log('<setOnboarding> ', response);
          this.toast.success('toast.technician.successOnboardingMessage', 'toast.technician.successOnboardingTitle');

          const toastId = this.plume.getObject('onboardedToast', false);
          this.plume.removeObject('onboardedToast');

          if (this.toast.check(toastId)) {
            this.toast.dismiss(toastId);
          }
        });
      };

      if (location.profile !== 'auto') {
        this.secondaryNetworks.getFronthaul$().subscribe((response) => {
          if (this.network.ssid === null) {
            this.toast.warning(
              'toast.technician.cannotCompleteOnboardingMessage',
              'toast.technician.cannotCompleteOnboardingTitle'
            );
          }

          if (!response.length) {
            this.toast.warning(
              'toast.technician.cannotCompleteOnboardingVSBMessage',
              'toast.technician.cannotCompleteOnboardingVSBTitle'
            );
          }

          if (this.network.ssid && response.length) {
            onboard();
          }
        });
      } else {
        if (this.network.ssid === null) {
          this.toast.warning(
            'toast.technician.cannotCompleteOnboardingMessage',
            'toast.technician.cannotCompleteOnboardingTitle'
          );
        } else {
          onboard();
        }
      }
    }
  }

  optimizationState(state: any, changedAt: number, init: boolean = false): void {
    if (this.optimizationChange < changedAt) {
      this.optimizationChange = changedAt;
      this.optimized = state;

      switch (state) {
        case 'optimized':
          this.optimizeState = 'optimized';
          if (!init) {
            this.toast.success(
              'toast.technician.successOptimizationCompletedMessage',
              'toast.technician.successOptimizationCompletedTitle'
            );
          }
          break;
        case 'failed':
        case 'fail':
          this.optimizeState = 'fail';
          if (!init) {
            this.toast.warning(
              'toast.technician.successOptimizationFailedMessage',
              'toast.technician.successOptimizationFailedTitle'
            );
          }

          break;
        case 'initiated':
        case 'inProgress':
          this.optimizeState = 'running';
          break;
        default:
          this.optimizeState = state;
      }
    }
  }

  triggerOptimize(): void {
    if (
      this.validateOptimization() &&
      (this.optimizeState !== 'running' || this.getOptimizationLabel() === 'resetRetry')
    ) {
      this.troubleShoot.triggerOptimization().subscribe(() => {
        this.mixpanel.storeEvent('MANUAL_OPTIMIZE', { SCREEN: 'TECH_DASHBOARD' });
        this.optimizeState = 'running';
        this.optimized = 'initiated';
        this.toast.success(
          'toast.technician.successOptimizationStartedMessage',
          'toast.technician.successOptimizationStartedTitle'
        );
      });
    }
  }

  getOptimizationLabel(): string {
    const lastChange = (new Date().getTime() - this.optimizationChange) / 1000 / 60; // duration in minutes between now and last change
    if (this.optimized === 'initiated' && lastChange > 60) {
      return 'resetRetry';
    }
    return this.optimized;
  }

  validateName(): boolean {
    const str = this.nameFormControl.value;
    return str?.length >= 4;
  }

  validateAccountId(): boolean {
    const str = this.accountIdFormControl.value;
    if (str) {
      const len = this.getUTF8Length(str.trim());
      return len > 0;
    }
    return false;
  }

  getUTF8Length(str: string): number {
    let utf8length = 0;
    for (let n = 0; n < str.length; n++) {
      const c = str.charCodeAt(n);
      if (c < 128) {
        utf8length++;
      } else if (c > 127 && c < 2048) {
        utf8length = utf8length + 2;
      } else {
        utf8length = utf8length + 3;
      }
    }
    return utf8length;
  }

  validateEmail(): boolean {
    const str = this.emailFormControl.value;
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(str).toLowerCase());
  }

  validateOptimization(): boolean {
    let counter = 0;
    for (const node of this.nodes) {
      if (node.connectionState === 'connected') {
        counter++;
      }
    }
    if (counter) {
      return true;
    } else {
      return false;
    }
  }

  toggleEditEmail(): void {
    this.setEmail = !this.setEmail;

    if (this.setEmail) {
      if (this.plume.hidePersonalDetails()) {
        this.emailFormControl.setValue('');
      } else {
        this.customer$.pipe(take(1)).subscribe((customer) => {
          this.emailFormControl.setValue(customer.email);
          this.mixpanel.storeEvent('TD_EDIT_EMAIL_ENABLE');
        });
      }
    } else {
      this.mixpanel.storeEvent('TD_EDIT_EMAIL_DISABLE');
    }
  }

  updateEmail(): void {
    if (this.validateEmail()) {
      this.customer$
        .pipe(
          take(1),
          switchMap((customer) =>
            this.customerService.setCurrentCustomerProperties$({ email: this.emailFormControl.value }).pipe(
              tap((newCustomer) => {
                this.mixpanel.storeEvent('TD_EDIT_EMAIL_SUCCESS', {
                  OLD_EMAIL: customer.email,
                  NEW_EMAIL: this.emailFormControl.value
                });

                this.setEmail = false;
                this.logging.log('Email Updated', newCustomer);
                this.toast.success('toast.technician.successEmailMessage', 'toast.technician.successEmailTitle');
                this.store.dispatch(customerSetTechnician({ customer: newCustomer }));
              }),
              catchError((error) => {
                this.mixpanel.storeEvent('TD_EDIT_EMAIL_ERROR', {
                  OLD_EMAIL: customer.email,
                  NEW_EMAIL: this.emailFormControl.value,
                  ERROR: error.error.error.message
                });

                this.setEmail = false;
                this.logging.error('Email not updated', error.error.error);
                this.toast.error('toast.technician.errorEmailMessage', 'toast.technician.errorEmailTitle', {
                  disableTimeOut: true,
                  params: {
                    error: error.error.error.message
                  }
                });
                throw error;
              })
            )
          )
        )
        .subscribe();
    }
  }

  toggleEditName(): void {
    this.setName = !this.setName;

    if (this.setName) {
      if (this.plume.hidePersonalDetails()) {
        this.nameFormControl.setValue('');
      } else {
        this.customer$.pipe(take(1)).subscribe((customer) => {
          this.nameFormControl.setValue(customer.name);
          this.mixpanel.storeEvent('TD_EDIT_NAME_ENABLE');
        });
      }
    } else {
      this.mixpanel.storeEvent('TD_EDIT_NAME_DISABLE');
    }
  }

  updateName(): void {
    if (this.validateName()) {
      this.customer$
        .pipe(
          take(1),
          switchMap((customer) =>
            this.customerService.setCurrentCustomerProperties$({ name: this.nameFormControl.value }).pipe(
              tap((newCustomer) => {
                this.mixpanel.storeEvent('TD_EDIT_NAME_SUCCESS', {
                  OLD_NAME: customer.name,
                  NEW_NAME: this.nameFormControl.value
                });

                this.setName = false;
                this.logging.log('Name Updated', newCustomer);
                this.toast.success('toast.technician.successNameMessage', 'toast.technician.successNameTitle');
                this.store.dispatch(customerSetTechnician({ customer: newCustomer }));
              }),
              catchError((error) => {
                this.mixpanel.storeEvent('TD_EDIT_NAME_ERROR', {
                  OLD_NAME: customer.name,
                  NEW_NAME: this.nameFormControl.value,
                  ERROR: error.error.error.message
                });

                this.setName = false;
                this.logging.error('Name not updated', error.error.error);
                this.toast.error('toast.technician.errorNameMessage', 'toast.technician.errorNameTitle', {
                  disableTimeOut: true,
                  params: {
                    error: error.error.error.message
                  }
                });
                throw error;
              })
            )
          )
        )
        .subscribe();
    }
  }

  toggleEditAccountId(): void {
    this.setAccountId = !this.setAccountId;

    if (this.setAccountId) {
      this.customer$.pipe(take(1)).subscribe((customer) => {
        this.accountIdFormControl.setValue(customer.accountId);
        this.mixpanel.storeEvent('TD_EDIT_ACCOUNT_ID_ENABLE');
      });
    } else {
      this.mixpanel.storeEvent('TD_EDIT_ACCOUNT_ID_DISABLE');
    }
  }

  updateAccountId(): void {
    if (this.validateAccountId()) {
      this.customer$
        .pipe(
          take(1),
          switchMap((customer) =>
            this.customerService
              .setCurrentCustomerProperties$({ accountId: this.accountIdFormControl.value.trim() })
              .pipe(
                tap((newCustomer) => {
                  this.mixpanel.storeEvent('TD_EDIT_ACCOUNT_ID_SUCCESS', {
                    OLD_ACCOUNT_ID: customer.accountId,
                    NEW_ACCOUNT_ID: this.accountIdFormControl.value.trim()
                  });

                  this.setAccountId = false;
                  this.logging.log('AccountID Updated', newCustomer);
                  this.toast.success(
                    'toast.technician.successAccountIdMessage',
                    'toast.technician.successAccountIdTitle'
                  );
                  this.store.dispatch(customerSetTechnician({ customer: newCustomer }));
                }),
                catchError((error) => {
                  this.mixpanel.storeEvent('TD_EDIT_ACCOUNT_ID_ERROR', {
                    OLD_ACCOUNT_ID: customer.accountId,
                    NEW_ACCOUNT_ID: this.accountIdFormControl.value.trim(),
                    ERROR: error.error.error.message
                  });

                  this.setAccountId = false;
                  this.logging.error('AccountID not updated', error.error.error);
                  this.toast.error('toast.technician.errorAccountIdMessage', 'toast.technician.errorAccountIdTitle', {
                    disableTimeOut: true,
                    params: {
                      error: error.error.error.message
                    }
                  });
                  throw error;
                })
              )
          )
        )
        .subscribe();
    }
  }

  exportCSV(): void {
    this.customer$.pipe(take(1)).subscribe((customer) => {
      const data = [];
      const universalBOM = '\uFEFF';
      const rssiKey = this.translate.instant('techdash.report.rssi');
      const optimizationStatusKey = this.translate.instant('techdash.report.optimizationStatus');
      const healthStatusKey = this.translate.instant('techdash.report.healthStatus');
      const healthScoreKey = this.translate.instant('techdash.report.healthScore');
      const onlineStatusKey = this.translate.instant('techdash.report.onlineStatus');
      const nodeNameKey = this.translate.instant('techdash.report.nodeName');
      const speedTestUpKey = this.translate.instant('techdash.report.speedTestUp');
      const speedTestDownKey = this.translate.instant('techdash.report.speedTestDown');
      const firmwareVersionKey = this.translate.instant('techdash.report.firmwareVersion');
      const reportTimeStampKey = this.translate.instant('techdash.report.reportTimeStamp');
      const backhaulChannelKey = this.translate.instant('techdash.report.backhaulChannel');
      const backhaulRadioFreqKey = this.translate.instant('techdash.report.backhaulRadioFreq');

      this.mixpanel.storeEvent('TD_SAVE_CSV_REPORT');

      this.nodes.forEach((node: INode) => {
        data.push({
          [reportTimeStampKey]: moment().utc().format('YYYY-MM-DD HH:mm:ss.S'),
          customerId: customer.id,
          accountId: customer.accountId,
          adminId: this.user.id,
          nodeSN: node.id,
          model: this.modelRef.get(node.model).name,
          [nodeNameKey]: node.nickname || node.name || node.defaultName,
          [optimizationStatusKey]: this.translate.instant(this.optimized),
          [healthStatusKey]: node.health ? node.health.status : node.connectionState,
          [healthScoreKey]: node.health ? Math.round(node.health.score * 100) / 100 : node.connectionState,
          [rssiKey]: this.getRssi(node),
          [speedTestUpKey]: node.speedTest ? Math.round(node.speedTest.upload * 100) / 100 : 'N/A',
          [speedTestDownKey]: node.speedTest ? Math.round(node.speedTest.download * 100) / 100 : 'N/A',
          [firmwareVersionKey]: node.firmwareVersion,
          [onlineStatusKey]: node.connectionState,
          [backhaulChannelKey]: node.backhaulChannel || 'N/A',
          [backhaulRadioFreqKey]: node.channel?.mode || 'N/A'
        });
      });

      const worker = new Parser();
      const csv = worker.parse(data);
      const blob = new Blob([universalBOM + csv], { type: 'text/csv;charset=utf8;' });
      const filename = customer.id + '_' + moment().utc().format('YYYYMMDDHHmmss') + '.csv';

      this.helper.download(blob, filename);
    });
  }

  getRssi(node: any): any {
    if (node.connectionState === 'disconnected') return 'disconnected';
    if (!node.health) return node.connectionState;
    if (node.rssi) return node.rssi;
    if (node.health.details) return Math.round((node.health.details.channelGain + 20) * 100) / 100;
    if (node.isGateway) return 'Gateway';
    return 'N/A';
  }

  openNetworkSettings(mode: string = null): void {
    this.networkSettingsMode = mode;
    this.showNetworkSettings = true;
  }

  getUpriseUrl() {
    const env = this.plume.getEnv();
    if (!this.isUprise || !this.partnerId || !this.locationId || !env?.upriseApplicationUrl) return;
    return `${env.upriseApplicationUrl}/partner/${this.partnerId}/location/${this.locationId}`;
  }

  openUprise() {
    if (this.getUpriseUrl()) window.open(this.getUpriseUrl());
  }

  stopSubscriptions(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((subscription: any) => subscription.unsubscribe());
    }

    if (this.firmware.updating) {
      clearTimeout(this.firmware.updating);
    }

    if (this.debouncerTimeout) {
      clearTimeout(this.debouncerTimeout);
    }
  }

  ngOnDestroy(): void {
    this.stopSubscriptions();
  }
}
