import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Moment } from 'moment';
import { combineLatest, forkJoin, iif, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import {
  selectBaseUrl,
  selectCustomer,
  selectCustomerIdentification,
  selectPipeLocationOnChange,
  selectPipeLocationOnChangeOptimistic,
  selectPrimarySecondaryNetworks,
  selectSingleCustomerId
} from 'src/app/store/customer/customer.selectors';
import { GeneralHelper } from '../helpers/general.helper';
import {
  autoModes,
  DeepPartial,
  IActiveIssue,
  IAddAccessZone,
  IAddAccessZoneKey,
  IAlarmReports,
  IAppTime,
  IBroadbandEfficiencyAlert,
  ICapabilities,
  IConfigAndState,
  ICurrentLanLatency,
  ICustomer,
  ICustomerGroup,
  ICustomerSupportConfig,
  IDHCPLeases,
  IEntitledAccess,
  IFlexEmployee,
  IFocus,
  IForceOnboardingRadios,
  IGuidedTroubleshootSubscribe,
  IInternet,
  IIpTv,
  ILocation,
  ILocationAuthorizations,
  ILocationBackhaul,
  ILocationDppConfiguration,
  ILocationLog,
  ILocationSecurityConfiguration,
  ILocationSteering,
  IMapConfig,
  IMembership,
  IMobilizeLog,
  IOptimizationSummary,
  IPrimarySecondaryNetworks,
  IPubnubSubscribe,
  ISchedule,
  ISearchNode,
  ISniffing,
  ISsoAuditTrail,
  ITaskStatuses,
  ITopology,
  ITopologyChange,
  IUprisePropertyLocationList,
  IVapType,
  IWanConfiguration,
  IWifiNetwork,
  logModes
} from '../interfaces/interface';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { LteService } from './lte.service';
import { MixpanelService } from './mixpanel.service';
import { PlumeService } from './plume.service';
import { ToastService } from './toast.service';

type CustomerSearchValue =
  | {
      searchType: 'GLOBAL_USER' | 'GROUPS_USER' | 'PARTNERS_USER';
      results: {
        accountId: string;
        email: string;
        id: string;
        name: string;
      }[];
    }
  | {
      searchType: 'GLOBAL_NODE' | 'GROUPS_NODE' | 'PARTNERS_NODE';
      results: Array<ISearchNode>;
    }
  | {
      searchType: 'GLOBAL_EMPLOYEE_ID' | 'GROUPS_EMPLOYEE_ID' | 'PARTNERS_EMPLOYEE_ID';
      results: IFlexEmployee['items'];
    }
  | {
      searchType:
        | 'ACCOUNT_ID'
        | 'CUSTOMER_ID'
        | 'GLOBAL_LOCATION_ID'
        | 'PARTNERS_LOCATION_ID'
        | 'GROUPS_LOCATION_ID'
        | 'SERVICE_ID';
      results: any[];
    };

@Injectable({ providedIn: 'root' })
export class CustomerService {
  constructor(
    private api: ApiService,
    private store: Store,
    private lte: LteService,
    private plume: PlumeService,
    private mixpanel: MixpanelService,
    private auth: AuthService,
    private toast: ToastService
  ) {}

  getCustomer(customerId: string): Observable<ICustomer> {
    if (this.auth.isExtendedAccess()) {
      return of({ id: customerId } as any);
    }

    return this.api.get('/Customers/' + customerId);
  }

  getCustomers$(customerId: string, secondaryCloud: boolean): Observable<(ICustomer & { cloud?: string })[]> {
    if (this.auth.isExtendedAccess()) {
      return of({ id: customerId } as any);
    }

    if (secondaryCloud) {
      return combineLatest([
        this.api.get('/Customers/' + customerId),
        this.api.getFromSecondaryCloud('/Customers/' + customerId)
      ]).pipe(
        map(([primary, secondary]) => {
          const results = [];
          if (primary) results.push(primary);
          if (secondary) results.push({ ...secondary, cloud: this.plume.getSecondaryCloud().name });
          return results;
        })
      );
    }

    return this.api.get('/Customers/' + customerId).pipe(map((r) => [r]));
  }

  deleteCustomer$(customerId: string): Observable<undefined> {
    return this.api.delete(`/Customers/${customerId}`);
  }

  getLocation(customerId: string, locationId: string): Observable<ILocation> {
    return this.api.get('/Customers/' + customerId + '/locations/' + locationId);
  }

  getLocationList$(customerId: string): Observable<ILocation[]> {
    return this.api.get('/Customers/' + customerId + '/locations');
  }

  getLocationListFromSecondaryCloud$(customerId: string): Observable<ILocation[]> {
    return this.api.getFromSecondaryCloud('/Customers/' + customerId + '/locations');
  }

  getUprisePropertyLocationList$(): Observable<IUprisePropertyLocationList> {
    return this.api.raw(
      'GET',
      `${this.plume.getEnv().upriseUrl}/v1/locations`,
      {},
      { headers: this.auth.getFrontlineHeader() }
    );
  }

  getLocationConfigAndState(): Observable<IConfigAndState> {
    if (this.plume.cloudVersionAbove1_90()) {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) => this.api.get(`${baseUrl}/v2/configAndState`)),
        catchError((error) => {
          if (error.status === 404) {
            // location and config probably not supported on cloud, use fallback solution
            return this.getLocationConfigAndStateFallback();
          }
          throw error;
        })
      );
    } else {
      return this.getLocationConfigAndStateFallback();
    }
  }

  getLocationConfigAndStateFallback(): Observable<IConfigAndState> {
    return combineLatest([this.lte.supported$().pipe(catchError(() => of(null)))]).pipe(
      take(1),
      map(
        ([supported]) =>
          ({
            state: {
              capabilities: { lte: supported }
            }
          } as IConfigAndState)
      )
    );
  }

  getDHCPLeases$(): Observable<IDHCPLeases> {
    if (!this.plume.isFlexRole()) {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) => this.api.get(`${baseUrl}/dhcpLeases`))
      );
    } else {
      return null;
    }
  }

  getWanConfiguration$(): Observable<IWanConfiguration> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/wanConfiguration`))
    );
  }

  setWanConfiguration$(data: DeepPartial<IWanConfiguration>): Observable<IWanConfiguration> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/wanConfiguration`, data))
    );
  }

  setSamKnows(mode: autoModes): Observable<void> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/config/samKnows`, { mode }))
    );
  }

  setFlashLogging(destination: logModes): Observable<void> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/config/flashLogging`, { forceDestination: destination }))
    );
  }

  setAppQoe(mode: autoModes): Observable<void> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/config/appQoe`, { mode }))
    );
  }

  setSipAlg(mode: autoModes): Observable<void> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/config/sipAlg`, { mode }))
    );
  }

  setPuncturing$(mode: string): Observable<void> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/config/puncturing`, { mode }))
    );
  }

  getCapabilities(): Observable<ICapabilities> {
    if (this.plume.cloudVersionAbove1_87()) {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) => this.api.get(`${baseUrl}/capabilities`))
      );
    }
    return this.store.pipe(
      selectPipeLocationOnChangeOptimistic,
      filter((location) => !!location),
      take(1),
      map((location) => ({
        networkConfiguration: {
          networkMode: { disabled: !location.capabilities?.networkConfiguration?.networkMode },
          upnp: { disabled: !location.capabilities?.networkConfiguration?.upnp },
          igmpSnooping: { disabled: !location.capabilities?.networkConfiguration?.igmpSnooping },
          igmpProxy: { disabled: !location.capabilities?.networkConfiguration?.igmpProxy },
          mldProxy: { disabled: !location.capabilities?.networkConfiguration?.mldProxy },
          multicastToUnicast: { disabled: !location.capabilities?.networkConfiguration?.multicastToUnicast },
          dnsServers: { disabled: !location.capabilities?.networkConfiguration?.dnsServers },
          dhcpReservations: { disabled: !location.capabilities?.networkConfiguration?.dhcpReservations },
          portForward: { disabled: !location.capabilities?.networkConfiguration?.portForward },
          dhcp: { disabled: !location.capabilities?.networkConfiguration?.dhcp },
          persistConfigurationOnGateway: {
            disabled: !location.capabilities?.networkConfiguration?.persistConfigurationOnGateway
          }
        },
        deviceFreeze: { disabled: !location.capabilities?.deviceFreeze },
        ispSpeedTest: { disabled: !location.capabilities?.ispSpeedTest },
        nodeIspSpeedTest: { disabled: !location.capabilities?.nodeIspSpeedTest },
        multiPasswordSSID: { disabled: !location.capabilities?.multiPasswordSSID },
        security: { dnsCategoryBlocking: { disabled: !location.capabilities?.security?.dnsCategoryBlocking } },
        wifiMotion: { disabled: !location.capabilities?.wifiMotion },
        appTime: { disabled: !location.capabilities?.appMetadata },
        wpa3: { wpaModes: [], recommendation: 'None' },
        dpp: false,
        haahs: false,
        lteBackup: false,
        mdns: false,
        multiPassword: false,
        powerManagement: location.capabilities?.powerManagement
      }))
    );
  }

  setPartnerId(partnerId: string): Observable<ICustomer> {
    return this.store.pipe(
      selectSingleCustomerId,
      switchMap((customerId) => this.api.patch('/Customers/' + customerId, { partnerId }))
    );
  }

  setCustomerProperties$(customerId: string, properties: Partial<ICustomer>): Observable<ICustomer> {
    return this.api.patch('/Customers/' + customerId, properties);
  }

  getCurrentLanLatency$(): Observable<ICurrentLanLatency> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`/${baseUrl}/currentLanLatency`, 'reports'))
    );
  }

  setCurrentCustomerProperties$(properties: Partial<ICustomer> = {}): Observable<ICustomer> {
    return this.store
      .pipe(selectSingleCustomerId)
      .pipe(switchMap((customerId) => this.setCustomerProperties$(customerId, properties)));
  }

  EnableOptimizationsAutoMode$(value: boolean): Observable<unknown> {
    return combineLatest([
      this.store.select(selectBaseUrl()),
      this.store.pipe(selectPipeLocationOnChangeOptimistic)
    ]).pipe(
      take(1),
      switchMap(([baseUrl, location]) =>
        this.api.put(`${baseUrl}/optimizations`, {
          auto: value,
          dfsMode: location.optimizations.dfsMode
        })
      )
    );
  }

  setOptimizationsDfsMode$(
    value: 'auto' | 'enable' | 'disable' | 'demo' | 'HomeNonDFSChannels' | 'usDfs'
  ): Observable<unknown> {
    return combineLatest([
      this.store.select(selectBaseUrl()),
      this.store.pipe(selectPipeLocationOnChangeOptimistic)
    ]).pipe(
      take(1),
      switchMap(([baseUrl, location]) =>
        this.api.put(`${baseUrl}/optimizations`, {
          auto: location.optimizations.auto,
          dfsMode: value
        })
      )
    );
  }

  setOptimizationsZeroWaitDfsMode$(value: 'auto' | 'enable' | 'disable'): Observable<unknown> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(`${baseUrl}/optimizations`, {
          zeroWaitDfsMode: value
        })
      )
    );
  }

  setOptimizationsPrefer160MHz$(value: 'auto' | 'enable' | 'disable'): Observable<unknown> {
    return combineLatest([
      this.store.select(selectBaseUrl()),
      this.store.pipe(selectPipeLocationOnChangeOptimistic)
    ]).pipe(
      take(1),
      switchMap(([baseUrl, location]) =>
        this.api.put(`${baseUrl}/optimizations`, {
          auto: location.optimizations.auto,
          prefer160MhzMode: value
        })
      )
    );
  }

  setOptimizationsMax24GhzChannelWidth$(
    value: 'CHANNEL_BANDWIDTH_AUTO' | 'CHANNEL_BANDWIDTH_20' | 'CHANNEL_BANDWIDTH_40'
  ): Observable<unknown> {
    return combineLatest([
      this.store.select(selectBaseUrl()),
      this.store.pipe(selectPipeLocationOnChangeOptimistic)
    ]).pipe(
      take(1),
      switchMap(([baseUrl, location]) =>
        this.api.put(`${baseUrl}/optimizations`, {
          auto: location.optimizations.auto,
          maxBandwidth: {
            maxBandwidth24g: value
          }
        })
      )
    );
  }

  setOptimizationsMax5GhzChannelWidth$(
    value: 'CHANNEL_BANDWIDTH_AUTO' | 'CHANNEL_BANDWIDTH_80' | 'CHANNEL_BANDWIDTH_160'
  ): Observable<unknown> {
    return combineLatest([
      this.store.select(selectBaseUrl()),
      this.store.pipe(selectPipeLocationOnChangeOptimistic)
    ]).pipe(
      take(1),
      switchMap(([baseUrl, location]) =>
        this.api.put(`${baseUrl}/optimizations`, {
          auto: location.optimizations.auto,
          maxBandwidth: {
            maxBandwidth5g: value
          }
        })
      )
    );
  }

  setOptimizationsMax6GhzChannelWidth$(
    value: 'CHANNEL_BANDWIDTH_AUTO' | 'CHANNEL_BANDWIDTH_80' | 'CHANNEL_BANDWIDTH_160' | 'CHANNEL_BANDWIDTH_320'
  ): Observable<unknown> {
    return combineLatest([
      this.store.select(selectBaseUrl()),
      this.store.pipe(selectPipeLocationOnChangeOptimistic)
    ]).pipe(
      take(1),
      switchMap(([baseUrl, location]) =>
        this.api.put(`${baseUrl}/optimizations`, {
          auto: location.optimizations.auto,
          maxBandwidth: {
            maxBandwidth6g: value
          }
        })
      )
    );
  }

  setUnii4Mode$(value: 'AUTO' | 'ENABLE' | 'DISABLE'): Observable<unknown> {
    return combineLatest([
      this.store.select(selectBaseUrl()),
      this.store.pipe(selectPipeLocationOnChangeOptimistic)
    ]).pipe(
      take(1),
      switchMap(([baseUrl]) =>
        // update here
        this.api.patch(`${baseUrl}/config/unii`, {
          unii4Mode: value
        })
      )
    );
  }

  setHopPenaltyMode$(value: 'auto' | 'low' | 'medium' | 'high'): Observable<unknown> {
    return combineLatest([
      this.store.select(selectBaseUrl()),
      this.store.pipe(selectPipeLocationOnChangeOptimistic)
    ]).pipe(
      take(1),
      switchMap(([baseUrl, location]) =>
        this.api.put(`${baseUrl}/optimizations`, {
          auto: location.optimizations.auto,
          hopPenalty: value
        })
      )
    );
  }

  setOptimizationsPreCACScheduler$(value: 'auto' | 'enable' | 'disable'): Observable<unknown> {
    return combineLatest([
      this.store.select(selectBaseUrl()),
      this.store.pipe(selectPipeLocationOnChangeOptimistic)
    ]).pipe(
      take(1),
      switchMap(([baseUrl, location]) =>
        this.api.put(`${baseUrl}/optimizations`, {
          auto: location.optimizations.auto,
          preCACScheduler: value
        })
      )
    );
  }

  setBandSteering$(value: 'auto' | 'enable' | 'disable'): Observable<ILocationSteering> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(`${baseUrl}/bandSteering`, {
          mode: value
        })
      )
    );
  }

  setServiceLevel$(
    value: 'fullService' | 'basicService' | 'noService' | 'reset'
  ): Observable<{ auto: boolean; status: string }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(`${baseUrl}/serviceLevel`, {
          status: value
        })
      )
    );
  }

  enableWifiNetwork$(value: boolean): Observable<IWifiNetwork> {
    return this.patchWifiNetwork$({
      enabled: value
    });
  }

  getWifiNetwork$(): Observable<{ wifiNetwork: IWifiNetwork }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/wifiNetwork`))
    );
  }

  patchWifiNetwork$(data: Partial<IWifiNetwork>): Observable<IWifiNetwork> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/wifiNetwork`, data))
    );
  }

  getBackhaul$(): Observable<ILocationBackhaul> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/backhaul`))
    );
  }

  updateBackhaul$(backhaul: ILocationBackhaul): Observable<ILocationBackhaul> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/backhaul`, backhaul))
    );
  }

  setLimitOnboardingRadios$(forceRadioConfig: IForceOnboardingRadios): Observable<IForceOnboardingRadios> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/event/forceOnboardingRadios`, forceRadioConfig))
    );
  }

  setBleMode$(mode: 'on' | 'off' | 'wps' | 'connectable'): Observable<undefined> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/bleMode`, { mode }))
    );
  }

  setStartWps$(data: { nodeId: string; keyId?: string; networkId?: string }): Observable<undefined> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/startWps`, data))
    );
  }

  setWifiNetworkPassword$(data: {
    ssid: string;
    encryptionKey: string;
    wpaMode: string;
  }): Observable<{ wifiNetwork: IWifiNetwork }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/wifiNetwork`, data))
    );
  }

  addAccessZone$(data: {
    description: string;
    type: 'guests';
    accessibleDevices: string[];
  }): Observable<IAddAccessZone> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/wifiNetwork/accessZones`, data))
    );
  }

  deleteAccessZone$(zoneId: number): Observable<IAddAccessZone[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}/wifiNetwork/accessZones/${zoneId}`))
    );
  }

  addAccessZoneKey$(
    zoneId: number | string,
    data: {
      enable: boolean;
      encryptionKey: string;
      expiresAt?: string;
      format: 'encryptionKey';
    }
  ): Observable<IAddAccessZoneKey[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/wifiNetwork/accessZones/${zoneId}/keys`, data))
    );
  }

  editAccessZoneKey$(
    zoneId: number,
    keyId: number,
    data: {
      encryptionKey: any;
      enable: any;
      format: 'encryptionKey';
    }
  ): Observable<IAddAccessZoneKey[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/wifiNetwork/accessZones/${zoneId}/keys/${keyId}`, data))
    );
  }

  deleteAccessZoneKey$(zoneId: number, keyId: number): Observable<IAddAccessZoneKey[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}/wifiNetwork/accessZones/${zoneId}/keys/${keyId}`))
    );
  }

  getHaas$(): Observable<{ mode: 'auto' | 'enable' | 'disable' }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/haahs`))
    );
  }

  setHaas$(mode: 'auto' | 'enable' | 'disable'): Observable<{ mode: 'auto' | 'enable' | 'disable' }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/haahs`, { mode }))
    );
  }

  getControlMode$(): Observable<{
    mode: 'full' | 'monitor' | 'reduced' | 'battery';
    modeRealized: 'full' | 'monitor' | 'reduced' | 'battery';
  }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/controlMode`))
    );
  }

  setControlMode$(
    value: 'full' | 'monitor' | 'reduced' | 'battery'
  ): Observable<{ mode: 'full' | 'monitor' | 'reduced' | 'battery' }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.put(`${baseUrl}/controlMode`, {
          mode: value
        })
      )
    );
  }

  setNetworkMode$(value: 'router' | 'bridge' | 'auto'): Observable<'router' | 'bridge' | 'auto'> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.put(`${baseUrl}/networkMode`, {
          networkMode: value
        })
      ),
      map((response) => response.location.networkMode)
    );
  }

  setServiceId$(value: string): Observable<unknown> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(`${baseUrl}`, {
          serviceId: value
        })
      )
    );
  }

  setLocationName$(value: string): Observable<unknown> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.put(`${baseUrl}`, {
          name: value
        })
      )
    );
  }

  archiveCurrentLocation$(): Observable<unknown> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}`))
    );
  }

  addNewLocation$(locationName: string, locationProfile: string): Observable<ILocation> {
    return this.store.pipe(
      selectSingleCustomerId,
      switchMap((customerId) =>
        this.api.post(`/Customers/${customerId}/locations`, { name: locationName, profile: locationProfile })
      )
    );
  }

  setNodesAuthorizations$(
    numNodesAuthorized: {
      model: string;
      count: number;
    }[]
  ): Observable<ILocationAuthorizations> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.put(`${baseUrl}/nodeAuthorizations`, {
          numNodesAuthorized
        })
      )
    );
  }

  setPodsAuthorizations$(numPodsAuthorized: number): Observable<ILocationAuthorizations> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.put(`${baseUrl}/authorizations`, {
          numPodsAuthorized
        })
      )
    );
  }

  setProfile$(profile: 'auto' | 'smallbusiness'): Observable<unknown> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(`${baseUrl}`, {
          profile
        })
      )
    );
  }

  setDpp$(mode: 'auto' | 'enable' | 'disable'): Observable<void> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(`${baseUrl}/dpp`, {
          mode
        })
      )
    );
  }

  getDpp$(): Observable<ILocationDppConfiguration> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/dpp`))
    );
  }

  getAppTime$(): Observable<IAppTime> {
    if (!this.plume.isFlexRole()) {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) => this.api.get(`${baseUrl}/appTime`))
      );
    } else {
      return throwError('Not supported');
    }
  }

  setAppTime$(appTime: IAppTime): Observable<boolean> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/appTime`, appTime))
    );
  }

  setSecurityConfigurationPreferredIntelligence$(
    preferredIntelligence: 'auto' | 'webpulse' | 'brightcloud' | 'gatekeeper'
  ): Observable<ILocationSecurityConfiguration> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(`${baseUrl}/securityConfiguration`, { securityConfig: { preferredIntelligence } })
      )
    );
  }

  setSecurityConfigurationIpThreatIntelligence$(
    ipThreatIntelligence: 'auto' | 'webpulse' | 'gatekeeper'
  ): Observable<ILocationSecurityConfiguration> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(`${baseUrl}/securityConfiguration`, { securityConfig: { ipThreatIntelligence } })
      )
    );
  }

  setSecurityConfigurationWcHealthTelemetry$(
    wcHealthTelemetry: 'auto' | 'enable' | 'disable'
  ): Observable<ILocationSecurityConfiguration> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(`${baseUrl}/securityConfiguration`, { securityConfig: { wcHealthTelemetry } })
      )
    );
  }

  setSecurityConfigurationWcHeroTelemetry$(
    wcHeroTelemetry: 'auto' | 'enable' | 'disable'
  ): Observable<ILocationSecurityConfiguration> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(`${baseUrl}/securityConfiguration`, { securityConfig: { wcHeroTelemetry } })
      )
    );
  }

  setSecurityConfigurationIpThreatProtect$(
    ipThreatProtect: 'auto' | 'enable' | 'disable'
  ): Observable<ILocationSecurityConfiguration> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(`${baseUrl}/securityConfiguration`, { securityConfig: { ipThreatProtect } })
      )
    );
  }

  setSecurityConfigurationInlineDpi$(
    inlineDpi: 'auto' | 'enable' | 'disable'
  ): Observable<ILocationSecurityConfiguration> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/securityConfiguration`, { securityConfig: { inlineDpi } }))
    );
  }

  setSecurityConfigurationDosProtection$(
    dosProtectionMode: 'auto' | 'enable' | 'disable'
  ): Observable<ILocationSecurityConfiguration> {
    return combineLatest([
      this.store.select(selectBaseUrl()),
      this.store.pipe(selectPipeLocationOnChangeOptimistic)
    ]).pipe(
      take(1),
      switchMap(([baseUrl, location]) =>
        this.api.patch(`${baseUrl}/securityConfiguration`, {
          securityConfig: {
            dosProtection: {
              mode: dosProtectionMode,
              action: location.securityConfiguration?.dosProtection?.action || 'auto'
            }
          }
        })
      )
    );
  }

  setSecurityConfigurationDosProtectionAction$(
    dosProtectionAction: 'auto' | 'observe' | 'block'
  ): Observable<ILocationSecurityConfiguration> {
    return combineLatest([
      this.store.select(selectBaseUrl()),
      this.store.pipe(selectPipeLocationOnChangeOptimistic)
    ]).pipe(
      take(1),
      switchMap(([baseUrl, location]) =>
        this.api.patch(`${baseUrl}/securityConfiguration`, {
          securityConfig: {
            dosProtection: {
              action: dosProtectionAction,
              mode: location.securityConfiguration?.dosProtection?.mode || 'auto'
            }
          }
        })
      )
    );
  }

  setSecurityConfigurationDpiContentFiltering$(
    dpiContentFiltering: 'auto' | 'enable' | 'disable'
  ): Observable<ILocationSecurityConfiguration> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(`${baseUrl}/securityConfiguration`, { securityConfig: { dpiContentFiltering } })
      )
    );
  }

  setSecurityConfigurationIpThreatProvider$(
    ipThreatProvider: 'auto' | 'plume' | 'norton'
  ): Observable<ILocationSecurityConfiguration> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(`${baseUrl}/securityConfiguration`, { securityConfig: { ipThreatProvider } })
      )
    );
  }

  setFlowStats$(value: { [key: string]: 'auto' | 'enable' | 'disable' }): Observable<unknown> {
    if (this.plume.cloudVersionAbove1_90()) {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) => this.api.patch(`${baseUrl}/flowStats/`, value))
      );
    } else {
      this.store.select(selectCustomerIdentification).pipe(
        take(1),
        switchMap(({ locationid }) => this.api.patch(`/locations/${locationid}/ipFlowStats/`, value, 'awlan'))
      );
    }
  }

  getPrimarySecondaryNetworks$(): Observable<IPrimarySecondaryNetworks> {
    if (!this.plume.isFlexRole()) {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) => this.api.get(`${baseUrl}/primarySecondaryNetworks`))
      );
    } else {
      return throwError('Not supported');
    }
  }

  setPrimarySecondaryNetworks$(options: IPrimarySecondaryNetworks): Observable<IPrimarySecondaryNetworks> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/primarySecondaryNetworks`, options))
    );
  }

  getCustomerSupportConfigurations$(): Observable<ICustomerSupportConfig> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/customerSupportConfigurations`)),
      catchError((error) => error)
    );
  }

  getSSIDs$(): Observable<{
    ssid: string | null;
    wpa2ssid: string | null;
    wpa2enabled: boolean;
    wpa3ssid: string | null;
    wpa3enabled: boolean;
  }> {
    return combineLatest([
      this.store.select(selectPrimarySecondaryNetworks),
      this.store.pipe(selectPipeLocationOnChange)
    ]).pipe(
      filter(([data, location]) => !!data && !!location),
      map(([data, location]) => {
        if (data.wpa2ssid && data.wpa3ssid && location.profile !== 'smallbusiness') {
          return {
            ssid: null,
            wpa2ssid: data.wpa2ssid,
            wpa2enabled: data.wpa2enabled,
            wpa3ssid: data.wpa3ssid,
            wpa3enabled: data.wpa3enabled
          };
        } else {
          let ssid = location.ssid;

          if (data.wpa2ssid && location.profile !== 'smallbusiness') {
            ssid = data.wpa2ssid;
          }

          if (data.wpa3ssid && location.profile !== 'smallbusiness') {
            ssid = data.wpa3ssid;
          }

          if (location.profile === 'smallbusiness') {
            if (data.wpa2ssid && data.wpa2enabled && !data.wpa3enabled) {
              ssid = data.wpa2ssid;
            }

            if (data.wpa3ssid && data.wpa3enabled && !data.wpa2enabled) {
              ssid = data.wpa3ssid;
            }
          }

          return {
            ssid,
            wpa2ssid: null,
            wpa2enabled: false,
            wpa3ssid: null,
            wpa3enabled: false
          };
        }
      }),
      catchError(() =>
        this.store.pipe(selectPipeLocationOnChange).pipe(
          take(1),
          map((location) => ({
            ssid: location.ssid,
            wpa2ssid: null,
            wpa2enabled: false,
            wpa3ssid: null,
            wpa3enabled: false
          }))
        )
      ),
      distinctUntilChanged((old, recent) => JSON.stringify(old) === JSON.stringify(recent))
    );
  }

  broadbandEfficiencyAlert$(): Observable<IBroadbandEfficiencyAlert> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/broadbandEfficiencyAlert`, 'reports'))
    );
  }

  membership$(): Observable<IMembership> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/subscription`, 'api'))
    );
  }

  internet$(): Observable<IInternet> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/appFacade/home?filters=summary,optimization`, 'api'))
    );
  }

  stability$(): Observable<IOptimizationSummary> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/optimizationSummary`, 'reports'))
    );
  }

  alarmReports$(): Observable<IAlarmReports> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/alarm?granularity=days&limit=7`, 'reports'))
    );
  }

  topology$(): Observable<ITopology> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/forceGraph?showAlsoIot=true`, 'api'))
    );
  }

  topologyChanges$(
    startTime: Date | Moment,
    endTime: Date | Moment,
    limit = 1000,
    order = 'desc'
  ): Observable<ITopologyChange[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.get(
          `${baseUrl}/topologyChanges?showAlsoIot=true&order=${order}&limit=${limit}&startAt=${startTime.toISOString()}&endAt=${endTime.toISOString()}`,
          'reports'
        )
      )
    );
  }

  optimizeRequests$(startTime: Date | Moment, endTime: Date | Moment, limit = 100, deep = true): Observable<string[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.get(
          `${baseUrl}/optimizeRequests?deep=${deep}&limit=${limit}&startAt=${startTime.toISOString()}&endAt=${endTime.toISOString()}`,
          'reports'
        )
      )
    );
  }

  pubnubSubscribe$(userId: string): Observable<IPubnubSubscribe> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.post(`${baseUrl}/subscribe`, { channelGroups: ['noc'], userId, ttl: 60 }, 'notifications')
      )
    );
  }

  guidedTroubleshootingPubnubSubscribe$(): Observable<IGuidedTroubleshootSubscribe> {
    const env = this.plume.getEnv();
    return this.store.select(selectCustomerIdentification).pipe(
      take(1),
      switchMap(({ locationid }) =>
        this.api.raw(
          'GET',
          env.signalUrl + `/reports/chatbot/locations/${locationid}/subscribe/pubnub`,
          {},
          { headers: this.auth.getFrontlineHeader() }
        )
      ),
      catchError((error) => error)
    );
  }

  getActiveIssues$(): Observable<IActiveIssue[]> {
    const env = this.plume.getEnv();
    return this.store.select(selectCustomerIdentification).pipe(
      take(1),
      switchMap(({ locationid }) =>
        this.api.raw(
          'GET',
          env.signalUrl + `/reports/chatbot/locations/${locationid}/activeIssues`,
          {},
          { headers: this.auth.getFrontlineHeader() }
        )
      ),
      catchError((error) => {
        throw error;
      })
    );
  }

  schedules$(): Observable<ISchedule[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/schedules`))
    );
  }

  addSchedule$(schedule: Pick<ISchedule, 'name' | 'type' | 'schedules'>): Observable<ISchedule> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/schedules`, schedule))
    );
  }

  updateSchedule$(id: string, schedule: Pick<ISchedule, 'name' | 'type' | 'schedules'>): Observable<ISchedule> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/schedules/${id}`, schedule))
    );
  }

  deleteSchedule$(id: string): Observable<void> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}/schedules/${id}`))
    );
  }

  getFocuses$(): Observable<IFocus[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/focuses`))
    );
  }

  postFocuses$(focus: IFocus): Observable<any> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/focuses`, focus))
    );
  }

  patchFocuses$(focus: IFocus): Observable<any> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/focuses/${focus.id}`, focus))
    );
  }

  deleteFocus$(id: string): Observable<any> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}/focuses/${id}`))
    );
  }

  entitledAccess$(customerId: string): Observable<IEntitledAccess[]> {
    return this.api.get(`/Customers/${customerId}/entitledAccess`);
  }

  getGroups$(customerId?: string): Observable<ICustomerGroup[]> {
    return (customerId ? of({ customerid: customerId }) : this.store.select(selectCustomerIdentification)).pipe(
      take(1),
      switchMap(({ customerid }) => this.api.get(`/Customers/${customerid}/groups`))
    );
  }

  resetPassword$(): Observable<string> {
    return combineLatest([this.store.select(selectCustomer), this.store.pipe(selectPipeLocationOnChange)]).pipe(
      take(1),
      switchMap(([customer, location]) =>
        this.api
          .post('/Customers/reset', { email: customer.email, notificationOptions: { profile: location.profile } })
          .pipe(map(() => customer.email))
      )
    );
  }

  resendEmailVerification$(customCustomer: { email: string; profile: string } = null): Observable<string> {
    if (customCustomer) {
      return this.api
        .post('/Customers/resendEmailVerification', {
          email: customCustomer.email,
          notificationOptions: { profile: customCustomer.profile }
        })
        .pipe(map(() => customCustomer.email));
    } else {
      return combineLatest([this.store.select(selectCustomer), this.store.pipe(selectPipeLocationOnChange)]).pipe(
        take(1),
        switchMap(([customer, location]) =>
          this.api
            .post('/Customers/resendEmailVerification', {
              email: customer.email,
              notificationOptions: { profile: location.profile }
            })
            .pipe(map(() => customer.email))
        )
      );
    }
  }

  logPullHistory$(fields: ('comment' | 'createdAt' | 'id')[], order: 'createdAt DESC'): Observable<ILocationLog[]> {
    const queryParams = JSON.stringify({
      fields: fields.reduce((acc, item) => ({ ...acc, [item]: true }), {} as { [key: string]: true }),
      order
    });

    return this.store.select(selectCustomerIdentification).pipe(
      take(1),
      switchMap(({ locationid }) => this.api.get(`/locations/${locationid}/logs?filter=${queryParams}`, 'awlan')),
      map((logs) =>
        logs.map((log) => ({ ...log, link: '/Logs/' + log.id, copyLink: this.api.apiURL('awlan') + '/Logs/' + log.id }))
      )
    );
  }

  mobilizeProblemsHistory$(): Observable<IMobilizeLog[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/securityPolicy/guard/ohp/problemReports`, 'reports'))
    );
  }

  locationTaskStatuses$(): Observable<ITaskStatuses> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/taskStatuses`))
    );
  }

  cloud$(): Observable<{ cloudUrl: string }> {
    return this.store.select(selectCustomerIdentification).pipe(
      take(1),
      switchMap((customer) => this.api.get(`/Customers/${customer.customerid}/cloud`))
    );
  }

  migrate$(cloud: string, migrationName: string): Observable<undefined> {
    return this.store.select(selectCustomerIdentification).pipe(
      take(1),
      switchMap((customer) =>
        this.api.post(`/Customers/${customer.customerid}/migrate`, {
          cloud,
          migrationName
        })
      )
    );
  }

  rollback$(): Observable<undefined> {
    return this.store.select(selectCustomerIdentification).pipe(
      take(1),
      switchMap((customer) => this.api.post(`/Customers/${customer.customerid}/rollback`, {}))
    );
  }

  shard$(): Observable<{ httpUrlBase: string; shard: string }> {
    return this.store.select(selectCustomerIdentification).pipe(
      take(1),
      switchMap((customer) => this.api.get(`/locations/${customer.locationid}/getShard`, 'awlan'))
    );
  }

  downloadLogPull$(logPullUrl: string): Observable<Blob[]> {
    return this.api.get(logPullUrl, 'awlan').pipe(
      map((response) =>
        Object.keys(response.podFilenameMap).map((nodeId) => ({
          logUrl: response.podFilenameMap[nodeId] as string,
          nodeId,
          headers: {
            responseType: 'blob',
            headers: {
              Accept: 'application/octet-stream',
              'Content-Type': 'application/octet-stream',
              ...this.auth.getHeaders(undefined, true)
            }
          }
        }))
      ),
      catchError((error) => {
        this.toast.error(
          'customerinfo.logpullHistory.awlanErrorToastMessage',
          'customerinfo.logpullHistory.downloadErrorToastTitle'
        );
        return throwError(error);
      }),
      mergeMap((urlsObj) =>
        forkJoin(
          urlsObj.map(({ logUrl, headers, nodeId }) =>
            (this.api.raw('get', logUrl + '?' + this.auth.getParams(), {}, headers) as Observable<Blob>).pipe(
              tap((data) => {
                const binaryData = [];
                binaryData.push(data);

                const blob = new Blob(binaryData, { type: data.type });
                const filename = logUrl.substring(logUrl.lastIndexOf('/') + 1);
                const helper = new GeneralHelper();

                helper.download(blob, filename);
              }),
              catchError((error) => {
                this.toast.error(
                  'configurations.utilities.history.downloadErrorToastMessage',
                  'configurations.utilities.history.downloadErrorToastTitle',
                  { params: { nodeId } }
                );
                return of(error);
              })
            )
          )
        )
      )
    );
  }

  resyncLocation$(): Observable<{ event: { locationId: string }[] }> {
    return this.store.select(selectCustomerIdentification).pipe(
      take(1),
      switchMap((customer) => this.api.put(`/locations/${customer.locationid}/resyncLocation`, {}))
    );
  }

  rebootLocation$(delay: number): Observable<undefined> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.put(`${baseUrl}/reboot`, {
          delay
        })
      )
    );
  }

  revertFocusLocation$(): Observable<undefined> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/revertFocusMigration`, {}))
    );
  }

  factoryReset$(retainWiFiNetwork: boolean): Observable<undefined> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.delete(
          `${baseUrl}/factoryReset?persons=true&onboardingCheckpoints=${retainWiFiNetwork}&devices=true&networkConfiguration=true&wifiNetwork=${retainWiFiNetwork}&secondaryNetworks=${retainWiFiNetwork}&deviceFreeze=true&deviceNicknames=true&managers=false&wanConfiguration=true`
        )
      )
    );
  }

  getSniffing$(): Observable<ISniffing> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/sniffing`))
    );
  }

  updateSniffing$(data: ISniffing): Observable<undefined> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/sniffing`, data))
    );
  }

  getIpFlow$(): Observable<{ enable: true; expiresAt?: string } | { enable: false }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/apptime/ipFlows`))
    );
  }

  updateIpFlow$(
    data: { enable: true; expiresAt?: string } | { enable: false }
  ): Observable<{ enable: true; expiresAt?: string } | { enable: false }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/apptime/ipFlows`, data))
    );
  }
  ssoAuditTrail$(): Observable<ISsoAuditTrail[]> {
    return this.store.select(selectCustomer).pipe(
      take(1),
      switchMap((customer) => this.api.get(`/auditTrail/${customer.partnerId}/${customer.accountId}`, 'provisioning'))
    );
  }

  getIpTv$(): Observable<{ vlanServices: IIpTv[] }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/vlanServices`))
    );
  }

  shiftyMacs$(): Observable<{ mode: 'perDevice' | 'disable' | 'auto'; modeRealized: 'perDevice' | 'disable' }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/shiftyMacs`, 'notifications'))
    );
  }

  newConnectionNotificationState$(): Observable<{
    mode: 'perDevice' | 'disable' | 'auto';
    modeRealized: 'perDevice' | 'disable';
  }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/newDeviceConnected`, 'notifications'))
    );
  }

  setShiftyMacsMode$(
    mode: 'perDevice' | 'disable' | 'auto'
  ): Observable<{ mode: 'perDevice' | 'disable' | 'auto'; modeRealized: 'perDevice' | 'disable' }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(
          `${baseUrl}/shiftyMacs`,
          {
            mode
          },
          'notifications'
        )
      )
    );
  }

  setNewConnectionNotificationMode$(mode: 'perDevice' | 'disable' | 'auto'): Observable<{
    mode: 'perDevice' | 'disable' | 'auto';
    modeRealized: 'perDevice' | 'disable';
  }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(
          `${baseUrl}/newDeviceConnected`,
          {
            mode
          },
          'notifications'
        )
      )
    );
  }

  getVapStates$(): Observable<IVapType[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/vapStates`))
    );
  }

  getMapConfig$(): Observable<IMapConfig> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/mapConfig`))
    );
  }

  setMapConfig$(config: IMapConfig): Observable<IMapConfig> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/mapConfig`, config))
    );
  }

  search$(
    query: string,
    type: 'user' | 'id' | 'node' | 'flex',
    exactMatch: boolean,
    secondaryCloud: boolean
  ): Observable<CustomerSearchValue> {
    const mode = this.plume.isPartner() ? 'PARTNERS' : this.plume.isGroupRole() ? 'GROUPS' : 'GLOBAL';

    switch (type) {
      case 'user':
        return this.searchByNameEmail$(query, exactMatch, secondaryCloud).pipe(
          map((results) => ({
            searchType: (mode + '_USER') as 'GLOBAL_USER' | 'GROUPS_USER' | 'PARTNERS_USER',
            results
          }))
        );
      case 'id':
        return this.searchById$(query, exactMatch, secondaryCloud);
      case 'node':
        return this.searchByNode$(query, secondaryCloud).pipe(
          map((results) => ({
            searchType: (mode + '_NODE') as 'GLOBAL_NODE' | 'GROUPS_NODE' | 'PARTNERS_NODE',
            results
          })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return throwError(error);
          })
        );
      case 'flex':
        return this.searchByFlex$(query).pipe(
          map((results) => ({
            searchType: (mode + 'EMPLOYEE_ID') as 'GLOBAL_EMPLOYEE_ID' | 'GROUPS_EMPLOYEE_ID' | 'PARTNERS_EMPLOYEE_ID',
            results: results.items
          })),
          catchError((error) => {
            this.toast.error(error.error.error.message, error.error.error.name);
            return throwError(error);
          })
        );
    }
  }

  private searchByNameEmail$(
    query: string,
    exactMatch: boolean,
    secondaryCloud: boolean
  ): Observable<
    {
      accountId: string;
      email: string;
      id: string;
      name: string;
      cloud?: string;
    }[]
  > {
    const searchEndpoint = this.searchCustomerEndPoint();

    const primaryCloudResults = combineLatest([
      this.api.get(searchEndpoint + '/search/' + query + '?field=name&exactMatch=' + exactMatch + '&startsWith=false'),
      this.api.get(searchEndpoint + '/search/' + query + '?field=email&exactMatch=' + exactMatch + '&startsWith=false')
    ]).pipe(
      map(([nameResponse, emailResponse]) => {
        const ids = new Set(nameResponse.map((r) => r.id));
        return [...nameResponse, ...emailResponse.filter((r) => !ids.has(r.id))];
      })
    );

    if (secondaryCloud) {
      const secondaryCloudResults = combineLatest([
        this.api.getFromSecondaryCloud(
          searchEndpoint + '/search/' + query + '?field=name&exactMatch=' + exactMatch + '&startsWith=false'
        ),
        this.api.getFromSecondaryCloud(
          searchEndpoint + '/search/' + query + '?field=email&exactMatch=' + exactMatch + '&startsWith=false'
        )
      ]).pipe(
        map(([nameResponse, emailResponse]) => {
          const ids = new Set((nameResponse || []).map((r) => r.id));
          return [...(nameResponse || []), ...(emailResponse || []).filter((r) => !ids.has(r.id))];
        })
      );

      return combineLatest([primaryCloudResults, secondaryCloudResults]).pipe(
        map(([primary, secondary]) => [
          ...primary,
          ...secondary.map((r) => ({ ...r, cloud: this.plume.getSecondaryCloud().name }))
        ])
      );
    }

    return primaryCloudResults;
  }

  private searchById$(
    query: string,
    exactMatch: boolean,
    secondaryCloud: boolean
  ): Observable<{
    searchType:
      | 'ACCOUNT_ID'
      | 'CUSTOMER_ID'
      | 'GLOBAL_LOCATION_ID'
      | 'PARTNERS_LOCATION_ID'
      | 'GROUPS_LOCATION_ID'
      | 'SERVICE_ID';
    results: any[];
  }> {
    return this.searchByAccountId$(query, exactMatch, secondaryCloud).pipe(
      switchMap((customers) =>
        iif(
          () => customers.results.length === 0 && query.length >= 24 && this.plume.getPermissions().search.idFallback,
          this.searchByLocation$(query, secondaryCloud).pipe(
            catchError(() =>
              this.getCustomers$(query, secondaryCloud).pipe(
                map((results) => ({ searchType: 'CUSTOMER_ID' as const, results })),
                catchError((error) =>
                  error.status === 404 &&
                  this.plume.getPermissions().search.serviceId &&
                  this.plume.cloudVersionAbove1_117()
                    ? this.searchByServiceId$(query, exactMatch, secondaryCloud)
                    : of({ searchType: 'CUSTOMER_ID' as const, results: [] })
                )
              )
            )
          ),
          iif(
            () =>
              customers.results.length === 0 &&
              this.plume.getPermissions().search.serviceId &&
              this.plume.cloudVersionAbove1_117(),
            this.searchByServiceId$(query, exactMatch, secondaryCloud),
            of(customers)
          )
        )
      )
    );
  }

  private searchByAccountId$(
    query: string,
    exactMatch: boolean,
    secondaryCloud: boolean
  ): Observable<{
    searchType: 'ACCOUNT_ID';
    results: {
      accountId: string;
      email: string;
      id: string;
      name: string;
    }[];
  }> {
    const searchEndpoint = this.searchCustomerEndPoint();

    return of(this.plume.isStrictAdminRole() || this.plume.isGroupRole() || this.plume.isPartner()).pipe(
      switchMap((useExactMatchFirst) => {
        if (useExactMatchFirst) {
          if (secondaryCloud) {
            return combineLatest([
              this.api.get(`${searchEndpoint}/search/${query}?field=accountId&exactMatch=true&startsWith=false`),
              this.api.getFromSecondaryCloud(
                `${searchEndpoint}/search/${query}?field=accountId&exactMatch=true&startsWith=false`
              )
            ]).pipe(
              map(([primary, secondary]) => [
                ...primary,
                ...secondary.map((r) => ({
                  ...r,
                  cloud: this.plume.getSecondaryCloud().name
                }))
              ])
            );
          } else {
            return this.api.get(`${searchEndpoint}/search/${query}?field=accountId&exactMatch=true&startsWith=false`);
          }
        } else {
          return of([]);
        }
      }),
      switchMap((customers) => {
        if (customers.length > 0 || exactMatch) {
          return of(customers);
        }

        if (secondaryCloud) {
          return combineLatest([
            this.api.get(`${searchEndpoint}/search/${query}?field=accountId&exactMatch=false&startsWith=false`),
            this.api.getFromSecondaryCloud(
              `${searchEndpoint}/search/${query}?field=accountId&exactMatch=false&startsWith=false`
            )
          ]).pipe(
            map(([primary, secondary]) => [
              ...primary,
              ...secondary.map((r) => ({
                ...r,
                cloud: this.plume.getSecondaryCloud().name
              }))
            ])
          );
        } else {
          return this.api.get(`${searchEndpoint}/search/${query}?field=accountId&exactMatch=false&startsWith=false`);
        }
      }),
      map((customers) => ({
        searchType: 'ACCOUNT_ID',
        results: customers
      }))
    );
  }

  private searchByLocation$(
    query: string,
    secondaryCloud: boolean
  ): Observable<{
    searchType: 'GLOBAL_LOCATION_ID' | 'PARTNERS_LOCATION_ID' | 'GROUPS_LOCATION_ID';
    results: { accountId: string; email: string; id: string; name: string; customer: { name: string; id: string } }[];
  }> {
    const mode = this.plume.isPartner() ? '/Partners' : '/Groups';

    if (this.plume.getPermissions().search.global) {
      if (secondaryCloud) {
        return combineLatest([
          this.api.get('/Customers/search/' + query + '?field=locationId&exactMatch=true&startsWith=true'),
          this.api.getFromSecondaryCloud(
            '/Customers/search/' + query + '?field=locationId&exactMatch=true&startsWith=true'
          )
        ]).pipe(
          map(([primary, secondary]) => [
            ...primary,
            ...secondary.map((r) => ({
              ...r,
              cloud: this.plume.getSecondaryCloud().name
            }))
          ]),
          switchMap((results) =>
            iif(
              () => results.length === 0,
              throwError('noResults'),
              of({
                searchType: 'GLOBAL_LOCATION_ID' as const,
                results: results.map((item) => ({ ...item, locationId: query }))
              })
            )
          )
        );
      }

      return this.api.get('/Customers/search/' + query + '?field=locationId&exactMatch=true&startsWith=true').pipe(
        switchMap((results) =>
          iif(
            () => results?.length === 0,
            throwError('noResults'),
            of({
              searchType: 'GLOBAL_LOCATION_ID' as const,
              results: results.map((item, index) => (index === 0 ? { ...item, locationId: query } : item))
            })
          )
        )
      );
    } else {
      if (secondaryCloud) {
        return combineLatest([
          this.api.get(mode + '/locations/' + query),
          this.api.getFromSecondaryCloud(mode + '/locations/' + query)
        ]).pipe(
          map(([primary, secondary]) => [
            ...primary,
            ...secondary.map((r) => ({
              ...r,
              cloud: this.plume.getSecondaryCloud().name
            }))
          ]),
          switchMap((results) =>
            iif(
              () => results.length === 0,
              throwError('noResults'),
              of({
                searchType: this.plume.isPartner() ? 'PARTNERS_LOCATION_ID' : ('GROUPS_LOCATION_ID' as any),
                results
              })
            )
          )
        );
      }

      return this.api.get(mode + '/locations/' + query).pipe(
        map((result) => ({
          searchType: this.plume.isPartner() ? 'PARTNERS_LOCATION_ID' : 'GROUPS_LOCATION_ID',
          results: [result]
        }))
      );
    }
  }

  private searchByNode$(query: string, secondaryCloud: boolean): Observable<ISearchNode[]> {
    const nodesEndpoint = this.plume.isPartner()
      ? '/Partners/nodes'
      : this.plume.isGroupRole()
      ? '/Groups/nodes'
      : '/Nodes';

    const primaryCloudResult: Observable<ISearchNode> = this.api.get(nodesEndpoint + '/' + query);
    let secondaryCloudResult: Observable<ISearchNode> = of(null);

    if (secondaryCloud) {
      secondaryCloudResult = this.api.getFromSecondaryCloud(nodesEndpoint + '/' + query);
    }

    return combineLatest([primaryCloudResult, secondaryCloudResult]).pipe(
      map(([primary, secondary]) => {
        const results = [];
        if (primary) results.push(primary);
        if (secondary) results.push({ ...secondary, cloud: this.plume.getSecondaryCloud().name });
        return results;
      }),
      catchError((error) => {
        if (query.match(/[a-z:]+/g)) {
          const search = query.replace(/:/g, '').toUpperCase();

          this.mixpanel.storeEvent('SEARCH', {
            SEARCH_TYPE: this.plume.isPartner()
              ? 'PARTNERS_NODE'
              : this.plume.isGroupRole()
              ? 'GROUPS_NODE'
              : 'GLOBAL_NODE',
            KEYWORD: query,
            REATTEMPT_KEYWORD: search,
            RESULT_COUNT: 0
          });

          if (secondaryCloud) {
            return combineLatest([
              this.api.get(nodesEndpoint + '/' + search),
              this.api.getFromSecondaryCloud(nodesEndpoint + '/' + search)
            ]).pipe(
              map(([primary, secondary]) => {
                const results = [];
                if (primary) results.push(primary);
                if (secondary) results.push({ ...secondary, cloud: this.plume.getSecondaryCloud().name });
                return results;
              })
            );
          }

          return this.api.get(nodesEndpoint + '/' + search).pipe(map((result) => [result]));
        }
        return throwError(error);
      }),
      catchError((error) => {
        if (this.plume.isPartner() && error.status > 300) {
          this.mixpanel.storeEvent('SEARCH_FAILED', {
            ERROR: error.error.error.message,
            SEARCH_TYPE: 'PARTNERS_NODE',
            KEYWORD: query,
            RESULT_COUNT: 0
          });

          if (secondaryCloud) {
            return combineLatest([
              this.api.get('/Groups/nodes/' + query),
              this.api.getFromSecondaryCloud('/Groups/nodes/' + query)
            ]).pipe(
              map(([primary, secondary]) => {
                const results = [];
                if (primary) results.push(primary);
                if (secondary) results.push({ ...secondary, cloud: this.plume.getSecondaryCloud().name });
                return results;
              })
            );
          }

          return this.api.get('/Groups/nodes/' + query).pipe(map((result) => [result]));
        }
        return throwError(error);
      })
    );
  }

  private searchByFlex$(query: string): Observable<IFlexEmployee> {
    const env = this.plume.getEnv();
    return this.api.raw(
      'GET',
      env.flexUrl + '/v1/locations?employeeId=' + query,
      {},
      { headers: this.auth.getFrontlineHeader() }
    );
  }

  private searchByServiceId$(
    query: string,
    exactMatch: boolean,
    secondaryCloud: boolean
  ): Observable<{
    searchType: 'SERVICE_ID';
    results: {
      accountId: string;
      email: string;
      id: string;
      name: string;
      locationId: string;
    }[];
  }> {
    if (secondaryCloud) {
      return combineLatest([
        this.api.get(
          this.searchCustomerEndPoint() +
            '/search/' +
            query +
            '?field=serviceId&exactMatch=' +
            exactMatch +
            '&startsWith=false'
        ),
        this.api.getFromSecondaryCloud(
          this.searchCustomerEndPoint() +
            '/search/' +
            query +
            '?field=serviceId&exactMatch=' +
            exactMatch +
            '&startsWith=false'
        )
      ]).pipe(
        map(([primary, secondary]) => [
          ...primary,
          ...secondary.map((r) => ({
            ...r,
            cloud: this.plume.getSecondaryCloud().name
          }))
        ]),
        map((results) => ({
          searchType: 'SERVICE_ID' as const,
          results
        }))
      );
    }

    return this.api
      .get(
        this.searchCustomerEndPoint() +
          '/search/' +
          query +
          '?field=serviceId&exactMatch=' +
          exactMatch +
          '&startsWith=false'
      )
      .pipe(map((results) => ({ searchType: 'SERVICE_ID' as const, results })));
  }

  private searchCustomerEndPoint(): string {
    return this.plume.isPartner()
      ? '/Partners/customers'
      : this.plume.isGroupRole()
      ? '/Groups/customers'
      : '/Customers';
  }
}
