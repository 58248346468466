import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { IPowerState, LPMStatus } from 'src/app/lib/interfaces/interface';
import { AuthService } from 'src/app/lib/services/auth.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { customerChange } from 'src/app/store/customer/customer.actions';
import { selectCustomer, selectPowerManagementState } from 'src/app/store/customer/customer.selectors';

@UntilDestroy()
@Component({
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit, OnDestroy {
  migrate: any = null;
  reloadComponents = false;

  powerPermission: boolean = false;
  powerState: IPowerState = null;
  powerCapable: boolean = false;
  powerMode: string = '';

  inactivity: any = {
    timeout: null,
    timer: 600,
    toast: {
      id: null,
      timeout: null,
      timer: 60
    }
  };

  feedbackModalOpen = false;

  @HostListener('document:click')
  extendInactivity(): void {
    this.clearTimers();
    this.inactivityTimer();
  }

  constructor(
    public plume: PlumeService,
    private router: Router,
    private toast: ToastService,
    private actions$: Actions,
    private mixpanel: MixpanelService,
    private auth: AuthService,
    private store: Store
  ) {
    this.actions$
      .pipe(
        ofType(customerChange),
        filter((customer) => customer.fromBrowserHistory),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.reloadComponents = true;

        setTimeout(() => {
          this.reloadComponents = false;
        }, 0);
      });
  }

  ngOnInit(): void {
    this.clearTimers();
    this.inactivityTimer();

    this.plume.permissions.pipe(untilDestroyed(this)).subscribe((data: any) => {
      this.powerPermission = data.uiFeatures?.powerManagement || false;
    });

    this.store
      .select(selectPowerManagementState)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.powerState = data.state;
        this.powerCapable = data.capable;
        this.powerMode = data.mode;
      });
  }

  isVolt(): boolean {
    return (
      this.powerPermission &&
      this.powerCapable &&
      this.powerState?.status !== LPMStatus.STATUS_INACTIVE &&
      this.powerState?.status !== LPMStatus.STATUS_UNKNOWN
    );
  }

  gotoMigrated(): void {
    this.mixpanel.storeEvent('MIGRATION_DIALOG_REDIRECT');
    window.location.href = this.migrate.url;
  }

  inactivityTimer(): void {
    const timeout = localStorage.getItem('inactivityTime') || this.inactivity.timer;

    this.inactivity.timeout = setTimeout(() => {
      const inactivityTimer = localStorage.getItem('inactivityTimer');
      if ((!inactivityTimer || inactivityTimer === 'enabled') && !this.auth.isExtendedAccess()) {
        this.inactivity.toast.id = this.toast.warning(
          'toast.customer.inactivityMessage',
          'toast.customer.inactivityTitle',
          { disableTimeOut: true, global: true, titleTimer: this.inactivity.toast.timer }
        );

        this.inactivity.toast.timeout = setTimeout(() => {
          this.store
            .select(selectCustomer)
            .pipe(take(1))
            .subscribe(() => {
              this.mixpanel.storeEvent('INACTIVITY_REDIRECT');
              this.router.navigate(['/']);
            });
        }, this.inactivity.toast.timer * 1000 + 1000);
      }
    }, timeout * 1000);
  }

  clearTimers(): void {
    if (this.inactivity.toast.id) {
      this.toast.dismiss(this.inactivity.toast.id);
    }

    if (this.inactivity.timeout) {
      clearTimeout(this.inactivity.timeout);
    }

    if (this.inactivity.toast.timeout) {
      clearTimeout(this.inactivity.toast.timeout);
    }
  }

  openFeedbackModal(): void {
    this.feedbackModalOpen = true;
  }

  closeFeedbackModal(): void {
    this.feedbackModalOpen = false;
  }

  ngOnDestroy(): void {
    this.clearTimers();
  }
}
