import { Component, OnDestroy, OnInit, ɵbypassSanitizationTrustHtml } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  selectAppPrioritization,
  selectAppPrioritizationMonitoring,
  selectAppPrioritizationV2,
  selectPipeLocationOnChange
} from 'src/app/store/customer/customer.selectors';
import { tap, takeUntil, skip, take, filter } from 'rxjs/operators';
import {
  DeepReadonly,
  IAppPrioritization,
  ILocation,
  ITrafficLegend,
  ITrafficConfigurationData,
  ITrafficGranularity,
  IDevice,
  IAppPrioritizationMonitoring,
  SlideTogglerItems,
  SlideTogglerItem,
  IAppPrioritizationV2
} from 'src/app/lib/interfaces/interface';
import { DataSetGroup } from 'src/app/lib/graph/graph.interface';
import { combineLatest, Subject } from 'rxjs';
import {
  deleteAppPrioritizationCustomSettings,
  getAppPrioritization,
  getAppPrioritizationMonitoring,
  getAppPrioritizationV2,
  setAppPrioritization,
  setAppPrioritizationV2,
  toggleAppPrioritization,
  toggleAppPrioritizationV2
} from 'src/app/store/customer/customer.actions';
import { selectDevices } from 'src/app/store/polling/polling.selector';
import { TrafficService } from 'src/app/lib/services/traffic.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import * as moment from 'moment';

type IPrioMode = 'auto' | 'enable' | 'disable';
type IMode = 'rxBytes' | 'txBytes';

@Component({
  selector: 'traffic-configuration',
  templateUrl: './trafficconfiguration.component.html',
  styleUrls: ['./trafficconfiguration.component.scss']
})
export class TrafficConfigurationComponent implements OnInit, OnDestroy {
  granularityItems: ITrafficGranularity[] = [];
  granularityItemsCurrent: ITrafficGranularity = null;
  modeItems: SlideTogglerItems<IMode> = [];
  modeItemsCurrent: SlideTogglerItem<IMode> = null;
  graphDataSet: DataSetGroup[] = [];
  avgDataSet: DataSetGroup[] = [];
  dataUsageChartDataset: {
    color: string;
    title: string;
    totalUsage: string;
    percentage: number;
    value: number;
  }[] = [];
  onlineMinutesChartDataset: {
    color: string;
    title: string;
    totalUsage: string;
    percentage: number;
    value: number;
  }[] = [];
  selectedDataOverviewIndex: number = -1;
  selectedOnlineOverviewIndex: number = -1;
  prioritization: { enabled: boolean; state: string } = {
    enabled: true,
    state: 'traffic.on'
  };
  prioModeItems: SlideTogglerItems<IPrioMode> = [];
  prioModeCurrent: SlideTogglerItem<IPrioMode> = null;
  legend: ITrafficLegend[] = [];
  avgLegend: ITrafficLegend[] = [];
  location: DeepReadonly<ILocation>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  templates: any = {
    current: null,
    list: []
  };
  categories: any = {
    current: null,
    list: []
  };
  boost: any = {
    current: null,
    currentExpiry: null,
    list: []
  };
  appPrioritization: IAppPrioritization = null;
  appPrioritizationV2: IAppPrioritizationV2 = null;
  priorities: any[] = [];
  helper: GeneralHelper = new GeneralHelper();
  devices: IDevice[] = null;
  monitoring: IAppPrioritizationMonitoring = null;
  APIv1108: boolean = false;
  APIv1122: boolean = false;
  isSomeSupport: boolean = false;
  disablePrioritySetting: boolean = true;
  editBoost: boolean = false;

  confirmPrioritizationChange = {
    message: 'traffic.prioritizationDialogMessage',
    confirmButton: 'traffic.prioritizationDialogOk'
  };

  constructor(
    private plume: PlumeService,
    private translate: TranslateService,
    private store: Store,
    private traffic: TrafficService
  ) {}

  ngOnInit(): void {
    this.initGranularity();
    this.initTemplates();
    this.initCategories();
    this.initBoostOptions();
    this.initMode();

    this.APIv1108 = this.plume.cloudVersionAbove1_108();
    this.APIv1122 = this.plume.cloudVersionAbove1_122();
    this.isSomeSupport = this.plume.isSomeSupportRole();
    this.disablePrioritySetting = this.plume.getPermissions()?.uiFeatures?.disablePrioritySetting ?? true;

    if (this.APIv1122) {
      this.store.dispatch(getAppPrioritizationV2());
    } else {
      this.store.dispatch(getAppPrioritization());
    }

    this.store
      .pipe(
        selectPipeLocationOnChange,
        takeUntil(this.destroy$),
        tap((location) => {
          this.location = location;
        })
      )
      .subscribe();

    this.store
      .select(selectAppPrioritization)
      .pipe(
        takeUntil(this.destroy$),
        filter((appPrioritization) => !!appPrioritization),
        tap((appPrioritization) => {
          this.appPrioritization = appPrioritization;

          this.initPrioritization(this.appPrioritization?.enabled || false);

          if (this.APIv1108) {
            this.initPrioMode();
          }

          this.templates.current = Object.keys(appPrioritization?.customSetting || {}).length
            ? 'custom'
            : appPrioritization?.template
            ? appPrioritization.template
            : 'default';

          if (this.templates.current === 'custom') {
            const priorities = [];

            Object.keys(appPrioritization.customSetting).forEach((setting) => {
              priorities.push({
                id: setting,
                name: 'traffic.' + setting,
                selected: appPrioritization.customSetting[setting],
                disabled: appPrioritization.customSetting[setting] === 'immutable',
                items: []
              });
            });

            this.priorities = priorities;

            this.initPriorities();
          }
        })
      )
      .subscribe();

    this.store
      .select(selectAppPrioritizationV2)
      .pipe(
        takeUntil(this.destroy$),
        filter((appPrioritizationV2) => !!appPrioritizationV2),
        tap((appPrioritizationV2) => {
          this.appPrioritizationV2 = appPrioritizationV2;

          this.initPrioritization(this.appPrioritizationV2?.enable || false);

          if (this.APIv1122) {
            this.initPrioModeV2();
          }
          this.boost.current = Object.keys(appPrioritizationV2?.personIds || {}).length
            ? 'byPerson'
            : Object.keys(appPrioritizationV2?.deviceIds || {}).length
            ? 'byDevice'
            : Object.keys(appPrioritizationV2?.category || {}).length
            ? 'byCategory'
            : 'noBoost';

          this.boost.currentExpiry =
            appPrioritizationV2?.category?.autoExpire.expiresAt ||
            appPrioritizationV2?.personIds?.[0]?.autoExpire.expiresAt ||
            appPrioritizationV2?.deviceIds?.[0]?.autoExpire.expiresAt;

          this.categories.current = appPrioritizationV2?.category?.id || 'noBoost';

          if (this.boost.current === 'byCategory') {
            const priorities = [];

            this.priorities = priorities;
          }
        })
      )
      .subscribe();

    combineLatest([
      this.store.select(selectDevices).pipe(
        filter((devices) => !!devices),
        take(1)
      ),
      this.store.select(selectAppPrioritizationMonitoring).pipe(skip(1))
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([devices, monitoring]) => {
        this.legend = this.traffic.legend;
        this.avgLegend = this.traffic.avgLegend;
        this.devices = devices ? devices : null;
        this.monitoring = monitoring ? monitoring : null;

        this.generateCharts();
      });
  }

  initTemplates(): void {
    this.templates.list = [
      {
        value: 'default',
        translation: 'traffic.default'
      },
      {
        value: 'fastGaming',
        translation: 'traffic.fastGaming'
      },
      {
        value: 'workFromHome',
        translation: 'traffic.workFromHome'
      },
      {
        value: 'movieTime',
        translation: 'traffic.movieTime'
      },
      {
        value: 'custom',
        translation: 'traffic.custom'
      }
    ];
  }

  initCategories(): void {
    this.categories.list = [
      {
        value: 'noBoost',
        translation: 'traffic.noBoost'
      },
      {
        value: 'fastGaming',
        translation: 'traffic.fastGaming'
      },
      {
        value: 'workFromHome',
        translation: 'traffic.workFromHome'
      },
      {
        value: 'movieTime',
        translation: 'traffic.movieTime'
      }
    ];
  }

  initBoostOptions(): void {
    this.boost.list = [
      {
        value: 'noBoost',
        translation: 'traffic.noBoost'
      },
      {
        value: 'byCategory',
        translation: 'traffic.byCategory'
      },
      {
        value: 'byPerson',
        translation: 'traffic.byPerson'
      },
      {
        value: 'byDevice',
        translation: 'traffic.byDevice'
      }
    ];
  }

  initPriorities(): void {
    this.priorities.forEach((priority: any) => {
      priority.disabled = priority.selected === 'immutable' ? true : false;

      priority.items = [
        { value: 'low', translation: 'traffic.low', selected: priority.selected === 'low' },
        { value: 'medium', translation: 'traffic.medium', selected: priority.selected === 'medium' },
        { value: 'high', translation: 'traffic.high', selected: priority.selected === 'high' }
      ];
    });
  }

  initGranularity(): void {
    this.granularityItems = [
      {
        value: '3h',
        granularity: 'total',
        miliseconds: 3 * 60 * 60 * 1000,
        translation: 'traffic.h3',
        count: 12,
        selected: false
      },
      {
        value: '24h',
        granularity: 'total',
        miliseconds: 24 * 60 * 60 * 1000,
        translation: 'traffic.h24',
        count: 24,
        selected: false
      },
      {
        value: '30d',
        granularity: 'total',
        miliseconds: 30 * 24 * 60 * 60 * 1000,
        translation: 'traffic.d30',
        count: 30,
        selected: true
      }
    ];

    this.changeGranularity();
  }

  initPrioMode(): void {
    if (!this.appPrioritization?.mode) {
      this.appPrioritization = {
        ...this.appPrioritization,
        mode: 'auto'
      };
    }

    this.prioModeItems = [
      { value: 'auto', translation: 'traffic.auto', selected: this.appPrioritization.mode === 'auto' },
      {
        value: 'enable',
        translation: 'traffic.enabled',
        selected: this.appPrioritization.mode === 'enable',
        marked: this.appPrioritization.modeRealized === 'enable'
      },
      {
        value: 'disable',
        translation: 'traffic.disabled',
        selected: this.appPrioritization.mode === 'disable',
        marked: this.appPrioritization.modeRealized === 'disable'
      }
    ];

    this.prioModeCurrent = this.prioModeItems[0];
  }

  initPrioModeV2(): void {
    this.prioModeItems = [
      {
        value: 'auto',
        translation: 'traffic.auto',
        selected: this.appPrioritizationV2.mode === 'auto' || !this.appPrioritizationV2.mode
      },
      {
        value: 'enable',
        translation: 'traffic.enabled',
        selected: this.appPrioritizationV2.mode === 'enable',
        marked: this.appPrioritizationV2.enable
      },
      {
        value: 'disable',
        translation: 'traffic.disabled',
        selected: this.appPrioritizationV2.mode === 'disable',
        marked: !this.appPrioritizationV2.enable
      }
    ];

    this.prioModeCurrent = this.prioModeItems[0];
  }

  formatDate(date: string): string {
    if (date) {
      return moment.utc(date).local().format('L LT');
    } else {
      return 'traffic.na';
    }
  }

  initMode(): void {
    this.modeItems = [
      { value: 'rxBytes', translation: 'traffic.download', selected: true },
      { value: 'txBytes', translation: 'traffic.upload', selected: false }
    ];

    this.modeItemsCurrent = this.modeItems[0];
  }

  changeGranularity(): void {
    this.granularityItemsCurrent = this.granularityItems.find((granularity) => granularity.selected);

    this.store.dispatch(
      getAppPrioritizationMonitoring({
        monitoring: {
          granularity: this.granularityItemsCurrent.granularity,
          startTime: new Date(Date.now() - this.granularityItemsCurrent.miliseconds).toISOString(),
          endTime: new Date().toISOString()
        }
      })
    );

    this.getAverageQoe();
  }

  changeMode(): void {
    this.modeItemsCurrent = this.modeItems.find((mode) => mode.selected);

    this.generateCharts();
  }

  generateCharts(): void {
    if (this.devices && this.monitoring) {
      if (this.monitoring.devices?.length) {
        const configuration = this.traffic.generateTrafficConfiguration(
          this.monitoring,
          this.devices,
          this.modeItemsCurrent.value
        );

        this.prepareStackedGraph(configuration.top5devices);
        this.prepareDataOverviewStackedChart(configuration.dataOverview);
        this.prepareOnlineOverviewStackedChart(configuration.onlineOverview);

        return;
      }
    }

    this.prepareStackedGraph(null);
    this.prepareDataOverviewStackedChart(null);
    this.prepareOnlineOverviewStackedChart(null);
  }

  getAverageQoe(): void {
    this.traffic
      .trafficClassStats$(
        'total',
        new Date(Date.now() - this.granularityItemsCurrent.miliseconds).toISOString(),
        new Date().toISOString()
      )
      .subscribe((response) => {
        if (response.devices?.length) {
          const stats = {
            av_streaming: { label: 'video', score: 0, count: 0 },
            voice: { label: 'voice', score: 0, count: 0 },
            gaming: { label: 'gaming', score: 0, count: 0 },
            video_conferencing: { label: 'videoConference', score: 0, count: 0 }
          };

          response.devices.forEach((device) => {
            device.trafficClasses.forEach((trafficClass) => {
              if (trafficClass.name !== 'other' && trafficClass.stats.score[0]) {
                stats[trafficClass.name].score += trafficClass.stats.score[0];
                stats[trafficClass.name].count++;
              }
            });
          });

          const data = Object.keys(stats).map((trafficClass) => ({
            name: trafficClass,
            label: stats[trafficClass].label,
            count: stats[trafficClass].count,
            score: stats[trafficClass].score / stats[trafficClass].count || 0
          }));

          const total = data.map((d) => d.count).reduce((accumulator, current) => accumulator + current);

          this.prepareAverageGraph(total ? data : []);
        } else {
          this.prepareAverageGraph([]);
        }
      });
  }
  selectCategoryBoost(boost: any): void {
    const MINUTES_TO_INCREASE = 86400; // 24Hrs

    if (boost.value === 'noBoost') {
      this.store.dispatch(
        setAppPrioritizationV2({
          appPrioritizationV2: {
            category: null,
            personIds: [],
            deviceIds: [],
            enable: true
          }
        })
      );
    } else {
      this.store.dispatch(
        setAppPrioritizationV2({
          appPrioritizationV2: {
            category: {
              id: boost.value,
              autoExpire: {
                expiresAt: new Date(Date.now() + MINUTES_TO_INCREASE * 1000).toISOString()
              }
            },
            personIds: [],
            deviceIds: [],
            enable: true
          }
        })
      );
    }
    this.editBoost = false;
  }

  selectTemplate(template: any): void {
    if (template.value === 'custom') {
      this.store.dispatch(
        setAppPrioritization({
          appPrioritization: {
            customSettingEnabled: true,
            customSetting: {
              videoConferencing: 'medium',
              voice: 'medium',
              videoStreaming: 'medium',
              gaming: 'medium'
            }
          }
        })
      );
    } else {
      if (this.templates.current === 'custom') {
        this.store.dispatch(
          deleteAppPrioritizationCustomSettings({
            appPrioritization: {
              template: template.value
            }
          })
        );
      } else {
        this.store.dispatch(
          setAppPrioritization({
            appPrioritization: {
              template: template.value,
              customSettingEnabled: false,
              customSetting: {}
            }
          })
        );
      }
    }
  }

  changePriority(priority: any, event: any): void {
    priority.selected = event;

    this.initPriorities();

    const customSetting = {};

    this.priorities.forEach((priority) => {
      customSetting[priority.id] = priority.disabled ? 'immutable' : priority.selected;
    });

    this.store.dispatch(
      setAppPrioritization({
        appPrioritization: {
          customSetting
        }
      })
    );
  }

  initPrioritization(force: boolean = null): void {
    if (force !== null) {
      this.prioritization.enabled = force;
    } else {
      this.prioritization.enabled = !this.prioritization.enabled;
    }

    if (this.prioritization.enabled) {
      this.prioritization.state = 'traffic.on';
    } else {
      this.prioritization.state = 'traffic.off';
    }
  }

  togglePrioritization(mode: 'auto' | 'enable' | 'disable' = null): void {
    if (mode) {
      this.store.dispatch(toggleAppPrioritization({ mode }));
    } else {
      this.initPrioritization();

      if (this.appPrioritization.template) {
        this.store.dispatch(toggleAppPrioritization({ enabled: this.prioritization.enabled }));
      } else {
        this.store.dispatch(
          setAppPrioritization({
            appPrioritization: {
              enabled: this.prioritization.enabled,
              customSettingEnabled: this.appPrioritization.custom_setting_enabled || false,
              template: this.appPrioritization.template || this.templates.current
            }
          })
        );
      }
    }
  }
  togglePrioritizationV2(mode: 'auto' | 'enable' | 'disable' = null): void {
    this.store.dispatch(toggleAppPrioritizationV2({ mode }));
  }

  prepareAverageGraph(averageTraffic: { name: string; label: string; score: number }[]): void {
    this.avgDataSet = averageTraffic.length
      ? [
          {
            startFromZero: true,
            autoScale: false,
            dataSets: [
              {
                type: 'segmentedIntervalBar',
                color: '#FFC500',
                yStart: 0,
                yEnd: 5,
                xLabelStart: 1,
                xLabelStep: 1,
                yLabelStep: 1,
                yLabelStart: 0,
                xStepVisualization: 'marker',
                yStepVisualization: 'line',
                xTextPosition: 'forceStart',
                yTextPosition: 'start',
                xTextMultiline: true,
                xTextStepOverflow: false,
                shrunkYIntervalOnOverflow: true,
                xStart: 0,
                xEnd: averageTraffic.length + 1,
                xValText: (val) => {
                  const label = averageTraffic.map((trafficClass) => trafficClass.label)[val - 1];
                  return label ? this.translate.instant('traffic.' + label) : '';
                },
                yValText: (val) => `${val}`,
                toolTipHtml: (data: any) =>
                  ɵbypassSanitizationTrustHtml(
                    `
                    <span>
                    ${this.translate.instant('traffic.' + data.yInterval[data.activeYIntervalIndex].dataObject.label)}
                    </span>
                    <br>
                    <div style="display: flex; align-items: center;">
                      <div style="background: ${
                        data.yInterval[data.activeYIntervalIndex].color
                      }; width: 8px; height: 8px; border-radius: 50%"></div>
                      <div style="margin-left: 0.5rem;">
                        ${this.translate.instant('qoe.charts.qoeScore')}
                      </div>
                      <div style="margin-left: 1.5rem; color: ${data.yInterval[data.activeYIntervalIndex].color};">${
                      Math.round((data.yInterval[data.activeYIntervalIndex].dataObject.score + Number.EPSILON) * 100) /
                      100
                    }</div>
                    </div>
                    `
                  ),
                data: averageTraffic.map((trafficClass, index) => {
                  if (trafficClass.score) {
                    return {
                      xVal: index + 1,
                      yVal: 0,
                      yInterval: [
                        {
                          start: 0,
                          end: trafficClass.score,
                          color: trafficClass.score >= 4 ? '#1AE3AE' : trafficClass.score >= 3 ? '#FFC500' : '#FC5FA3',
                          dataObject: trafficClass
                        }
                      ]
                    };
                  } else {
                    return {
                      xVal: index + 1,
                      yVal: 0
                    };
                  }
                })
              }
            ]
          }
        ]
      : [];
  }

  prepareStackedGraph(monitoring: ITrafficConfigurationData[]): void {
    this.graphDataSet = monitoring
      ? [
          {
            startFromZero: true,
            autoScale: false,
            dataSets: [
              {
                type: 'segmentedIntervalBar',
                color: '#FFC500',
                yStart: 0,
                yEnd: 100,
                xLabelStart: 1,
                xLabelStep: 1,
                yLabelStep: 25,
                yLabelStart: 0,
                xStepVisualization: 'marker',
                yStepVisualization: 'line',
                xTextPosition: 'forceStart',
                yTextPosition: 'start',
                xTextMultiline: true,
                xTextStepOverflow: false,
                shrunkYIntervalOnOverflow: true,
                xStart: 0,
                xEnd: monitoring.length + 1,
                xValText: (val) => monitoring.map((device) => device.name)[val - 1],
                yValText: (val) => (!val ? `${val}` : `${val}%`),
                toolTipHtml: (data: any) =>
                  ɵbypassSanitizationTrustHtml(
                    `
                    <span>
                    ${monitoring.map((device) => device.name)[data.xVal - 1]}
                    </span>
                    <br>
                    <div style="display: flex; align-items: center;">
                      <div style="background: ${
                        data.yInterval[data.activeYIntervalIndex].color
                      }; width: 8px; height: 8px; border-radius: 50%"></div>
                      <div style="margin-left: 0.5rem; color: ${data.yInterval[data.activeYIntervalIndex].color};">
                        ${this.translate.instant(data.yInterval[data.activeYIntervalIndex].dataObject.label)}
                      </div>
                      <div style="margin-left: 1.5rem">${
                        data.yInterval[data.activeYIntervalIndex].dataObject.value
                      }</div>
                    </div>
                    `
                  ),
                data: monitoring.map((device, index) => ({
                  xVal: index + 1,
                  yVal: 0,
                  yInterval: device.classes.reduce(
                    (acc, trafficClass) => {
                      const usage = this.helper.formatBytes(trafficClass.bytes, 'Bytes');
                      return {
                        result: [
                          ...acc.result,
                          {
                            start: acc.start,
                            end: acc.start + trafficClass.percent,
                            color: trafficClass.color,
                            dataObject: {
                              value: usage.value + ' ' + usage.unit,
                              label: trafficClass.label
                            }
                          }
                        ],
                        start: acc.start + trafficClass.percent
                      };
                    },
                    { start: 0, result: [] }
                  ).result
                }))
              }
            ]
          }
        ]
      : [];
  }

  prepareDataOverviewStackedChart(dataOverview: ITrafficConfigurationData): void {
    if (!dataOverview) {
      this.dataUsageChartDataset = [];
      this.selectedDataOverviewIndex = -1;
      return;
    }

    let largest = 0;
    let largestIndex = 0;

    dataOverview.classes.forEach((trafficClass, index) => {
      if (largest < trafficClass.bytes) {
        largest = trafficClass.bytes;
        largestIndex = index;
      }
    });

    this.selectedDataOverviewIndex = largestIndex;

    this.dataUsageChartDataset = dataOverview.classes.map((trafficClass) => {
      const usage = this.helper.formatBytes(trafficClass.bytes, 'Bytes');

      return {
        color: trafficClass.color,
        title: trafficClass.label,
        totalUsage: usage.value + ' ' + usage.unit,
        percentage: Math.round(((trafficClass.bytes * 100) / dataOverview.total + Number.EPSILON) * 100) / 100,
        value: trafficClass.bytes
      };
    });
  }

  minutesToString(totalMinutes: number) {
    if (totalMinutes === 0) {
      return '0min';
    }

    const days = Math.floor(totalMinutes / 1440);
    const hours = Math.floor((totalMinutes % 1440) / 60);
    const minutes = totalMinutes % 60;

    let result = '';

    if (days > 0) {
      result += days + 'd';
      if (hours > 0 || minutes > 0) result += ', ';
    }

    if (hours > 0) {
      result += hours + 'h';
      if (minutes > 0) result += ', ';
    }

    if (minutes > 0 || (days === 0 && hours === 0)) {
      result += minutes + 'min';
    }

    return result;
  }

  prepareOnlineOverviewStackedChart(onlineOverview: ITrafficConfigurationData): void {
    if (!onlineOverview) {
      this.onlineMinutesChartDataset = [];
      this.selectedOnlineOverviewIndex = -1;
      return;
    }

    let largest = 0;
    let largestIndex = 0;

    onlineOverview.classes.forEach((trafficClass, index) => {
      if (largest < trafficClass.minutes) {
        largest = trafficClass.minutes;
        largestIndex = index;
      }
    });

    this.selectedOnlineOverviewIndex = largestIndex;

    this.onlineMinutesChartDataset = onlineOverview.classes.map((trafficClass) => ({
      color: trafficClass.color,
      title: trafficClass.label,
      totalUsage: this.minutesToString(trafficClass.minutes),
      percentage: Math.round(((trafficClass.minutes * 100) / onlineOverview.total + Number.EPSILON) * 100) / 100,
      value: trafficClass.minutes
    }));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
